import {
  Category,
  Mutation,
  Region,
  StoreType,
  typesEnumeration,
} from '../../types';

type MutationType =
  | 'setOneTimeToken'
  | 'setState'
  | 'setLoading'
  | 'setRegions'
  | 'setCategories';

const mutations: Mutation<MutationType, StoreType.NewCurrency> = {
  setOneTimeToken(state, payload) {
    state.oneTimeToken = payload;
  },
  setState(state, payload) {
    state.pageState = payload;
  },
  setLoading(state, { key, bool }: { key: string; bool: boolean }) {
    state.loading = { ...state.loading, [key]: bool };
  },
  setCategories(state, payload: Category[]) {
    state.categories = payload;
  },
  setRegions(state, payload: Region[]) {
    state.regions = payload;
  },
};

export const MutationType = typesEnumeration(mutations);
export default mutations;
