<template>
  <div id="app">
    <load v-if="loading" />
    <router-view v-else />
  </div>
</template>

<script>
import { MutationType as ErrorMutations } from './store/modules/error/mutations';
import { NormalAlert } from './components/Alert';
import { ActionTypes } from './store/actions';
import * as Sentry from '@sentry/browser';
import Load from './layouts/Loading';

export default {
  name: 'app',
  components: {
    Load,
  },
  computed: {
    loading() {
      return this.$store.state.loading.entry;
    },
  },
  mounted() {
    this.$store.subscribe((mutation, state) => {
      if (mutation.type === `error/${ErrorMutations.pushError}`) {
        this.$notify.error({
          title: 'Error',
          message: this.$createElement(NormalAlert, {
            props: {
              message: mutation.payload.error.message(),
            },
          }),
        });
        //ステータスコードで識別するのがベスト？
        if (
          mutation.payload.error.message() === '別端末でログインされました' ||
          mutation.payload.error.message() ===
            '権限がなくなったため、ログアウトされました'
        ) {
          setTimeout(() => {
            this.$router.go('/login');
          }, 1000);
        }
        this.$store.dispatch(`error/${ErrorMutations.popError}`);
      }
    });
  },
};
</script>

<style lang="scss" scoped>
#app {
  background-color: #f2f2f2;
  font-family: 'Hiragino Kaku Gothic ProN', 'ヒラギノ角ゴ ProN W3', Meiryo,
    メイリオ, Osaka, 'MS PGothic', arial, helvetica, sans-serif;
  min-height: 100vh;
  min-width: 100vw;
  height: 100%;
  width: 100%;
}
</style>
