import { StoreType, Mutation, typesEnumeration, CurrencyInfo } from './types';

type MutationType =
  | 'setIsAlive'
  | 'setToken'
  | 'setIsLogin'
  | 'setLoading'
  | 'setDateRange'
  | 'setIsAdmin'
  | 'setCurrentCurrency'
  | 'setCurrentCurrencyInfo';

const mutations: Mutation<MutationType, StoreType.RootState> = {
  setIsAlive(store, payload: boolean) {
    store.isAlive = payload;
  },
  setToken(store, token) {
    store.token = token;
  },
  setIsLogin(store, payload) {
    store.isLogin = payload;
  },
  setLoading(store, { name, isLoading }: { name: string; isLoading: boolean }) {
    store.loading[name] = isLoading;
  },
  setDateRange(store, { dateRange }) {
    store.dateRange = dateRange;
  },
  setIsAdmin(state, isAdmin: boolean) {
    state.isAdmin = isAdmin;
  },
  setCurrentCurrency(state, id: string) {
    state.currentCurrencyId = id;
  },
  setCurrentCurrencyInfo(state, info: CurrencyInfo) {
    state.currencyInfo = info;
  },
};

export const MutationTypes = typesEnumeration(mutations);
export default mutations;
