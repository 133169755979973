import Vue from 'vue';
import Router from 'vue-router';
import { routerConfig } from '@/config/routes';
import Store from './store';
import { ActionTypes } from './store/actions';

Vue.use(Router);

const router = new Router({
  routes: routerConfig,
});

const isLoginGuard = (to, _, next) => {
  if (Store.state.isAdmin && to.query.id) {
    Store.dispatch(ActionTypes.setCurrentCurrency, {
      id: to.query.id,
      currentRoute: to.path,
    });
  }
  if (to.query.start && to.query.end) {
    const start = new Date(parseInt(to.query.start));
    const end = new Date(parseInt(to.query.end));
    Store.dispatch(ActionTypes.changeDateRange, [start, end]);
  }
  if (to.query.no_date === null) {
    Store.dispatch(ActionTypes.changeDateRange, []);
  }
  if (to.query.external) {
    window.location.replace(to.query.external);
  }

  if (to.path === '/login') {
    next({ path: '/', replace: true });
  } else {
    next();
  }
};

const defaultGuard = (to, _, next) => {
  if (to.path === '/login') {
    next();
  } else {
    next({
      path: '/login',
      replace: true,
      query: { ...to.query, redirect: to.path },
    });
  }
};

router.beforeEach((to, from, next) => {
  if (to.path !== '/mentainance' && !Store.state.isAlive) {
    next({ path: '/mentainance' });
  } else if (Store.state.isLogin) {
    isLoginGuard(to, from, next);
  } else {
    defaultGuard(to, from, next);
  }
});

export default router;
