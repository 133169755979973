import { Module } from 'vuex';
import { StoreType } from '../../types';
import mutations from './mutation';
import actions from './actions';

// state
const state: StoreType.ChangePassword = {
  state: 'INPUT_EMAIL',
  email: '',
  oldPassword: '',
  newPassword: '',
  oneTimeToken: '',
  pinCode: '',
  loading: {
    preChange: false,
    change: false,
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
} as Module<StoreType.ChangePassword, StoreType.RootState>;
