// 地域 0~8 + 全国地方公共団体コード
export const regionWeight = {
  全国: 0,
  北海道: 1010006,
  東北: 2000000,
  '東北・青森県': 2020001,
  '東北・岩手県': 2030007,
  '東北・宮城県': 2040002,
  '東北・秋田県': 2050008,
  '東北・山形県': 2060003,
  '東北・福島県': 2070009,
  '東北・会津': 2072028,
  関東: 3000000,
  '関東・茨城県': 3080004,
  '関東・栃木県': 3090000,
  '関東・群馬県': 3100005,
  '関東・埼玉県': 3110001,
  '関東・千葉県': 3120006,
  '関東・東京都': 3130001,
  東京: 3130001,
  '関東・神奈川県': 3140007,
  中部: 4000000,
  '中部・新潟県': 4150002,
  '中部・山梨県': 4190004,
  '中部・長野県': 4200000,
  '中部・富山県': 4160008,
  '中部・石川県': 4170003,
  '中部・福井県': 4180009,
  '中部・岐阜県': 4210005,
  '中部・静岡県': 4220001,
  '中部・愛知県': 4230006,
  近畿: 5000000,
  '近畿・三重県': 5240001,
  '近畿・滋賀県': 5250007,
  '近畿・京都府': 5260002,
  '近畿・大阪府': 5270008,
  '近畿・兵庫県': 5280003,
  '近畿・奈良県': 5290009,
  '近畿・和歌山県': 5300004,
  中国: 6000000,
  '中国・鳥取県': 6310000,
  '中国・島根県': 6320005,
  '中国・岡山県': 6330001,
  '中国・広島県': 6340006,
  '中国・山口県': 6350001,
  四国: 7000000,
  '四国・徳島県': 7360007,
  '四国・香川県': 7370002,
  '四国・愛媛県': 7380008,
  '四国・高知県': 7390003,
  '九州・沖縄': 8000000,
  '九州・沖縄・福岡県': 8400009,
  '九州・沖縄・佐賀県': 8410004,
  '九州・沖縄・長崎県': 8420000,
  '九州・沖縄・熊本県': 8430005,
  '九州・沖縄・大分県': 8440001,
  '九州・沖縄・宮崎県': 8450006,
  '九州・沖縄・鹿児島県': 8460001,
  '九州・沖縄・沖縄県': 8470007,
};

export const compareRegion = (a, b) => {
  if (!(a in regionWeight)) return -1;
  if (!(b in regionWeight)) return 1;
  return regionWeight[a] <= regionWeight[b] ? -1 : 1;
};
