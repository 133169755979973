<template>
  <el-container v-loading="loading['sign-out']" class="wrapper">
    <global-header
      :isAdmin="isAdmin"
      :breadcrumb="breadcrumb"
      :hasDateRange="hasDateRange"
      :dateRange="dateRange"
      :showError="showError"
      :errMsg="errMsg"
      :width="width"
      @change-date="(x) => setChangeDateRange(x || [])"
      @focus="() => setError('')"
    />
    <el-container class="content-wrapper">
      <el-aside class="side" width="200px">
        <side-bar
          :menus="execMenus"
          :active="active"
          style="overflow: hidden"
        />
      </el-aside>
      <el-main>
        <router-view v-if="isNoSlot" />
        <slot v-else />
      </el-main>
    </el-container>
  </el-container>
</template>

<script>
import SideBar from '@/components/SideBar';
import GlobalHeader from './GlobalHeader.vue';
import InlineDatePicker from '@/components/InlineDatePicker.vue';
import { mapActions, mapState } from 'vuex';
import { ActionTypes } from '@/store/actions';

export default {
  name: 'LayoutWithSidebar',
  components: {
    SideBar,
    GlobalHeader,
    InlineDatePicker,
  },
  props: {
    menus: {
      type: [Array, Function],
      required: true,
    },
    breadcrumb: {
      type: Array,
      required: true,
    },
    minWidth: {
      type: Number,
      default: 135,
    },
    maxWidth: {
      type: Number,
      default: 300,
    },
    active: {
      type: String,
      required: true,
    },
    hasDateRange: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      errMsg: '',
      width: window.innerWidth,
    };
  },
  computed: {
    ...mapState(['dateRange', 'loading', 'isAdmin']),
    // propsに関数が渡される可能性があるのでそれを解決するためのプロパティ
    execMenus() {
      if (this.menus instanceof Function) {
        return this.menus();
      } else {
        return this.menus;
      }
    },
    isNoSlot() {
      return Object.keys(this.$slots).length === 0;
    },
    showError() {
      return this.errMsg.length > 0;
    },
  },
  methods: {
    ...mapActions([ActionTypes.signOut]),
    setError(msg) {
      if (msg) {
        this.errMsg = msg;
      } else {
        this.errMsg = '';
      }
    },
    isInNinetyDay(x) {
      const time = x[1].getTime() - x[0].getTime();
      const ninetyday = 90 * 24 * 60 * 60 * 1000;
      return time <= ninetyday;
    },
    checkDateRange(x) {
      if (x.length > 0 && !this.isInNinetyDay(x)) {
        this.setError('選択範囲は3ヶ月以内に設定してください。');
        return false;
      } else {
        this.setError('');
        return true;
      }
    },
    setChangeDateRange(dateRange) {
      if (this.checkDateRange(dateRange)) {
        if (dateRange.length < 2) {
          const { start, end, ...query } = this.$route.query;
          this.$router.replace({
            query: {
              ...query,
              no_date: null,
            },
          });
        } else {
          const [start, end] = dateRange.map((x) => x.getTime());
          const { no_date, ...query } = this.$route.query;
          this.$router.replace({
            query: {
              ...query,
              start,
              end,
            },
          });
        }
      }
    },
    resize() {
      this.width = window.innerWidth;
    },
  },
  mounted() {
    window.addEventListener('resize', this.resize);
  },
  destroyed() {
    window.addEventListener('resize', this.resize);
  },
};
</script>

<style lang="scss" scoped>
.wrapper {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.content-wrapper {
  min-height: calc(100vh - 60px);
  display: flex;
  flex-direction: row;
}

.side {
  background: white;
}
</style>
