<template>
  <admin-header
    v-if="isAdmin"
    :currencyList="currencyList"
    :currentCurrencyId="currentCurrencyId"
    :hasDateRange="hasDateRange"
    :dateRange="dateRange"
    :showError="showError"
    :errMsg="errMsg"
    :width="width"
    @changeDate="(x) => $emit('change-date', x)"
    @select="changeCurrentCurrency"
    @focus="$emit('focus')"
  >
    <template v-slot:left>
      <div class="logo-wrapper">
        <el-image lazy class="logo-style" :src="logo" fit="contain" />
        <h1 class="header-title">CC Wallet Console</h1>
      </div>
    </template>
    <template v-slot:right>
      <el-dropdown @command="logOut">
        <el-button icon="el-icon-user" circle />
        <el-dropdown-menu slot="dropdown">
          <el-dropdown-item command="logout">LogOut</el-dropdown-item>
        </el-dropdown-menu>
      </el-dropdown>
    </template>
  </admin-header>
  <breadcrumb-header
    v-else
    :breadcrumb="breadcrumb"
    :hasDateRange="hasDateRange"
    :dateRange="dateRange"
    :showError="showError"
    :errMsg="errMsg"
    @changeDate="(x) => $emit('change-date', x)"
    @focus="$emit('focus')"
  >
    <template v-slot:left>
      <div class="logo-wrapper">
        <el-image class="logo-style" :src="logo" fit="contain" />
        <h1 class="header-title">CC Wallet Console</h1>
      </div>
    </template>
    <template v-slot:right>
      <el-dropdown @command="logOut">
        <el-button icon="el-icon-user" circle />
        <el-dropdown-menu slot="dropdown">
          <el-dropdown-item command="logOut">LogOut</el-dropdown-item>
        </el-dropdown-menu>
      </el-dropdown>
    </template>
  </breadcrumb-header>
</template>

<script>
import AdminHeader from '@/components/AdminHeader.vue';
import BreadcrumbHeader from '@/components/BreadcrumbHeader.vue';
import { mapGetters, mapActions, mapState } from 'vuex';
import { ActionTypes as RootActions } from '@/store/actions';
import { ActionTypes as CurrencyListActions } from '@/store/modules/currencyList/actions';
import logo from '@/assets/logo.png';

export default {
  components: {
    AdminHeader,
    BreadcrumbHeader,
  },
  props: {
    isAdmin: {
      type: Boolean,
      required: true,
    },
    breadcrumb: {
      type: Array,
      required: true,
    },
    hasDateRange: {
      type: Boolean,
      required: true,
    },
    dateRange: {
      type: Array,
      required: true,
    },
    showError: {
      type: Boolean,
      default: false,
    },
    errMsg: {
      type: String,
      default: '',
    },
    width: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      logo,
    };
  },
  computed: {
    ...mapState(['currentCurrencyId']),
    ...mapGetters('currencyList', ['currencyList']),
  },
  methods: {
    ...mapActions([RootActions.signOut, RootActions.setCurrentCurrency]),
    ...mapActions('currencyList', [CurrencyListActions.getCurrencies]),
    ...mapActions('user', ['setPageIdx']),
    async logOut() {
      try {
        await this.signOut();
        await this.$router.push({ path: '/login' });
      } catch (e) {
        console.error(e);
      }
    },
    async changeCurrentCurrency(id) {
      await this.setCurrentCurrency({
        id: id,
        currentRoute: this.$route.path,
      });
      this.setCurrencyIdQuery(id);
      this.setPageIdx(1);
    },
    setCurrencyIdQuery(id) {
      this.$router
        .replace({
          query: {
            ...this.$route.query,
            id: id,
          },
        })
        .catch((err) => console.error(err));
    },
  },
};
</script>

<style lang="scss" scoped>
.logo-wrapper {
  display: flex;

  .logo-style {
    height: 36px;
    width: 36px;
  }

  .header-title {
    margin-left: 0.5rem;
    font-size: 1rem;
  }
}
</style>
