import {
  StoreType,
  Mutation,
  typesEnumeration,
  Category,
  Region,
} from '../../types';

type MutationType = 'setCategories' | 'setRegions';

// mutations
const mutations: Mutation<MutationType, StoreType.Admin> = {
  setRegions(state, { regions }: { regions: Region[] }) {
    state.regions = [...regions].sort((a, b) => {
      return a.id < b.id ? -1 : 1;
    });
  },
  setCategories(state, { categories }: { categories: Category[] }) {
    state.categories = categories;
  },
};

export const MutationTypes = typesEnumeration(mutations);
export default mutations;
