import { Mutation, StoreType, typesEnumeration } from '../../../store/types';

type MutationType = 'pushError' | 'popError';

const mutations: Mutation<MutationType, StoreType.ErrorState> = {
  pushError(state, { error }) {
    state.errors = [...state.errors, error];
  },
  popError(state) {
    const [_, ...errors] = state.errors;
    state.errors = errors;
  },
};

export const MutationType = typesEnumeration(mutations);
export default mutations;
