import { StoreType, Mutation, Currency, typesEnumeration } from '../../types';

type MutationType = 'setCurrencies';

const mutations: Mutation<MutationType, StoreType.CurrencyList> = {
  setCurrencies(state, payload: Currency[]) {
    state.currencies = payload.reduce<{ [uuid in string]: Currency }>(
      (acc, x) => ({ ...acc, [x.uuid]: x }),
      {}
    );
  },
};

export const MutationTypes = typesEnumeration(mutations);
export default mutations;
