import * as Vuex from 'vuex';
import { Action, StoreType, typesEnumeration } from '@/store/types';
import { MutationType } from '@/store/modules/editCurrency/mutations';
import { ActionTypes as ErrorActionTypes } from '../error/actions';
import API from '@/api';
import * as Sentry from '@sentry/browser';
import { ActionTypes as AdminActionTypes } from '@/store/actions';

type ActionType = 'patchName' | 'patchDescription' | 'patchUnit';

const actions: Action<
  ActionType,
  StoreType.EditCurrency,
  StoreType.RootState
> = {
  async patchName(
    { state, commit, rootState, dispatch },
    payload: { name: string }
  ) {
    try {
      commit(MutationType.setLoading, true);
      const req = rootState.isAdmin
        ? API.Admin.patchCurrencyNameWithId(rootState.currentCurrencyId)
        : API.Account.patchName;
      await req(rootState.token, { name: payload.name });
      commit(MutationType.setIsFailed, false);
    } catch (e) {
      commit(MutationType.setIsFailed, true);
      dispatch(`error/${ErrorActionTypes.pushError}`, e, { root: true });
      Sentry.captureException(new Error('patchName failure'));
    } finally {
      commit(MutationType.setLoading, false);
      dispatch(AdminActionTypes.getCurrencyInfo, null, { root: true });
    }
  },
  async patchDescription(
    { state, commit, rootState, dispatch },
    payload: { description: string }
  ) {
    try {
      commit(MutationType.setLoading, true);
      const req = rootState.isAdmin
        ? API.Admin.patchCurrencyDescriptionWithId(rootState.currentCurrencyId)
        : API.Account.patchDescription;
      await req(rootState.token, { description: payload.description });
      commit(MutationType.setIsFailed, false);
    } catch (e) {
      commit(MutationType.setIsFailed, true);
      dispatch(`error/${ErrorActionTypes.pushError}`, e, { root: true });
      Sentry.captureException(new Error('patchDescription failure'));
    } finally {
      commit(MutationType.setLoading, false);
      dispatch(AdminActionTypes.getCurrencyInfo, null, { root: true });
    }
  },
  async patchUnit({ commit, rootState, dispatch }, payload: { unit: string }) {
    try {
      commit(MutationType.setLoading, true);
      const req = rootState.isAdmin
        ? API.Admin.patchCurrencyUnitWithId(rootState.currentCurrencyId)
        : API.Account.patchUnit;
      await req(rootState.token, { unit: payload.unit });
      commit(MutationType.setIsFailed, false);
    } catch (e) {
      commit(MutationType.setIsFailed, true);
      dispatch(`error/${ErrorActionTypes.pushError}`, e, { root: true });
      Sentry.captureException(new Error('patchUnit failure' + e));
    } finally {
      commit(MutationType.setLoading, false);
      dispatch(AdminActionTypes.getCurrencyInfo, null, { root: true });
    }
  },
};

export const ActionTypes = typesEnumeration(actions);
export default actions;
