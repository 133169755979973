import {
  Currency,
  Mutation,
  Payment,
  StoreType,
  typesEnumeration,
} from '../../types';

type MutationType =
  | 'setUsers'
  | 'setUserSize'
  | 'setMethod'
  | 'setQRType'
  | 'setSelectedUsers'
  | 'setPoint'
  | 'setMessage'
  | 'setSheetNum'
  | 'setState'
  | 'setSending'
  | 'setQR'
  | 'setIsFailed'
  | 'setOrgAmount'
  | 'setTargetCurrency'
  | 'setPayment';

const mutations: Mutation<MutationType, StoreType.Collection> = {
  setUsers(state, payload) {
    state.users = payload;
  },
  setUserSize(state, payload: number) {
    state.userSize = payload;
  },
  setMethod(state, payload) {
    state.method = payload;
  },
  setQRType(state, payload) {
    state.qrType = payload;
  },
  setSelectedUsers(state, payload) {
    state.selectedUsers = payload;
  },
  setPoint(state, payload) {
    state.point = payload;
  },
  setMessage(state, payload) {
    state.message = payload;
  },
  setSheetNum(state, payload) {
    state.sheetNum = payload;
  },
  setState(state, payload) {
    state.currentState = payload;
  },
  setSending(state, payload) {
    state.sending = payload;
  },
  setQR(state, payload) {
    // state.qrSrc = payload;
    state.qrs = payload;
  },
  setIsFailed(state, payload) {
    state.isFailed = payload;
  },
  setOrgAmount(state, payload) {
    state.orgAmount = payload;
  },
  setTargetCurrency(state, payment: Currency) {
    state.targetCurrency = payment;
  },
  setPayment(state, payload: Payment) {
    state.payment = payload;
  },
};

export const MutationTypes = typesEnumeration(mutations);
export default mutations;
