import * as Vuex from 'vuex';
import { StoreType, Action, typesEnumeration, User } from '@/store/types';
import { MutationType } from '@/store/modules/organization/mutations';
import { ActionTypes as ErrorActionType } from '../error/actions';
import API from '@/api';
import * as Sentry from '@sentry/browser';

type ActionType =
  | 'initState'
  | 'getUsers'
  | 'getTradeHistory'
  | 'getUserHistory'
  | 'getUserSize'
  | 'setLimit'
  | 'setOffset';

const actions: Action<
  ActionType,
  StoreType.Organization,
  StoreType.RootState
> = {
  async initState({ dispatch, rootState }) {
    await dispatch(ActionTypes.getUserSize, rootState.currentCurrencyId);
    await dispatch(ActionTypes.getUsers, rootState.currentCurrencyId);
    await dispatch(ActionTypes.getTradeHistory, rootState.currentCurrencyId);
    dispatch(ActionTypes.getUserHistory, rootState.currentCurrencyId);
    dispatch(ActionTypes.setOffset, 0);
  },
  async getUsers({ rootState, state, commit, dispatch }) {
    const req = rootState.isAdmin
      ? API.Admin.getUsersWithIdAndOption(rootState.currentCurrencyId)
      : API.Account.getUsersWithOption;

    try {
      let begins = [];
      for (let i = 0; i < state.userSize; i += 100) {
        begins = [...begins, i];
      }
      let users: User[];
      await Promise.all(
        begins.map((b) =>
          req(b, Math.min(state.userSize - b, 100))(rootState.token)
        )
      ).then((us) => {
        users = us.flat();
      });
      commit(MutationType.setUsers, users);
    } catch (e) {
      dispatch(`error/${ErrorActionType.pushError}`, e, {
        root: true,
      });
      Sentry.captureException(new Error('getUsers failure'));
    }
  },
  async getTradeHistory({ rootState, commit, dispatch }) {
    commit(MutationType.setTradeHistoryLoading, true);
    try {
      const req = rootState.isAdmin
        ? API.Admin.getHistoryWithId(rootState.currentCurrencyId)
        : API.Account.getTradeHistory;
      const tradeHistories = await req(rootState.token);
      commit(MutationType.setTradeHistory, { tradeHistories });
    } catch (e) {
      Sentry.captureException(new Error('getTradeHistory failure'));
      dispatch(`error/${ErrorActionType.pushError}`, e, { root: true });
    } finally {
      commit(MutationType.setTradeHistoryLoading, false);
    }
  },
  async getUserHistory({ rootState, state, commit, dispatch }) {
    commit(MutationType.setUserHistoryLoading, true);
    try {
      const req = rootState.isAdmin
        ? API.Admin.getUsersHistoryWithId(rootState.currentCurrencyId)
        : API.Account.getUserHistory;

      const userIDs = Object.keys(state.users);
      const history = await req(rootState.token, {
        // regTest016がいるとエラーが出るので一旦
        users: userIDs.filter((x) => x !== 'regTest016'),
      }).then((xss) =>
        xss
          .flatMap((xs, i) => xs.map((x) => ({ ...x, partner: userIDs[i] })))
          .sort((a, b) => b.date.getTime() - a.date.getTime())
      );
      commit(MutationType.setUserHistory, { history });
    } catch (e) {
      Sentry.captureException(new Error('getUserHistory failure'));
      dispatch(`error/${ErrorActionType.pushError}`, e, {
        root: true,
      });
    } finally {
      commit(MutationType.setUserHistoryLoading, false);
    }
  },
  async getUserSize({ rootState, commit, dispatch }) {
    const req = rootState.isAdmin
      ? API.Admin.getUsersTotalwithId(rootState.currentCurrencyId)
      : API.Log.totalPeople;
    try {
      const { value: userSize } = await req(rootState.token);
      commit(MutationType.setUserSize, userSize);
    } catch (e) {
      Sentry.captureException(new Error('getUserSize failure'));
      dispatch(`error/${ErrorActionType.pushError}`, e, { root: true });
    }
  },
  setLimit({ commit }, limit: number) {
    commit(MutationType.setLimit, limit);
  },
  setOffset({ commit }, offset: number) {
    commit(MutationType.setOffset, offset);
  },
};
export const ActionTypes = typesEnumeration(actions);
export default actions as Vuex.ActionTree<
  StoreType.Organization,
  StoreType.RootState
>;
