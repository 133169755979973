import {
  StoreType,
  Action,
  typesEnumeration,
  Currency,
  CurrencyInfo,
} from './types';
import API from '../api';
import { MutationTypes } from './mutations';
import { ActionTypes as ErrorActionType } from './modules/error/actions';
import * as Sentry from '@sentry/browser';

type ActionType =
  | 'initApp'
  | 'healthCheck'
  | 'checkToken'
  | 'checkAdmin'
  | 'signIn'
  | 'signOut'
  | 'setLoading'
  | 'getTokenFromLocalStorage'
  | 'changeDateRange'
  | 'initCurrentCurrency'
  | 'setCurrentCurrency'
  | 'getCurrencyInfo'
  | 'initState';

const TOKEN_KEY = 'SESSION_TOKEN';

const actions: Action<ActionType, StoreType.RootState> = {
  async initApp({ state, commit, dispatch }) {
    try {
      commit(MutationTypes.setLoading, { name: 'entry', isLoading: true });
      await dispatch(ActionTypes.healthCheck);
      if (!state.isAlive) {
        throw Error('Sever is not alive!');
      }
      await dispatch(ActionTypes.getTokenFromLocalStorage);
      await dispatch(ActionTypes.checkToken);
      if (!state.isLogin) {
        return;
      }
      await dispatch(ActionTypes.checkAdmin);
      if (state.isAdmin) {
        await dispatch(`currencyList/getCurrencies`);
        await dispatch(ActionTypes.initCurrentCurrency);
      }
    } catch (e) {
      Sentry.captureException(new Error('failed initialize App' + e));
      console.error('failed initialize App', e);
    } finally {
      commit(MutationTypes.setLoading, { name: 'entry', isLoading: false });
    }
  },
  async initState({ state, dispatch }, path: string) {
    await dispatch(ActionTypes.getCurrencyInfo, state.currentCurrencyId);
    switch (path) {
      case '':
      case '/graph':
        await dispatch('console/initState');
        break;
      case '/user':
        await dispatch('user/initState');
        break;
      case '/network':
        await dispatch('tradeNetwork/initState');
        break;
      case '/history':
        await dispatch('organization/initState');
        break;
      case '/edit-currncy':
        await dispatch('editCurrency/initState');
        break;
      default:
        break;
    }
  },
  async healthCheck({ commit }) {
    try {
      const isAlive = await API.Admin.healthCheck();
      if (isAlive) {
        commit(MutationTypes.setIsAlive, true);
      } else {
        commit(MutationTypes.setIsAlive, false);
      }
    } catch (e) {
      Sentry.captureException(new Error('health check' + e));
      console.error('/health', e);
    }
  },
  async checkToken({ state, commit }) {
    try {
      const { isToken } = await API.Account.checkToken(state.token);
      if (!isToken) {
        commit(MutationTypes.setToken, '');
        commit(MutationTypes.setIsLogin, false);
      } else {
        commit(MutationTypes.setIsLogin, true);
      }
    } catch (e) {
      Sentry.captureException(new Error('token invalid' + e));
      console.error('/token', e);
    }
  },
  async checkAdmin({ rootState, commit }) {
    try {
      const isAdmin = await API.Admin.checkIsAdmin(rootState.token);
      commit(MutationTypes.setIsAdmin, isAdmin);
    } catch (e) {
      commit(MutationTypes.setIsAdmin, false);
    }
  },
  async signIn({ state, commit, dispatch }, payload) {
    try {
      commit(MutationTypes.setLoading, { name: 'sign-in', isLoading: true });
      const { token } = await API.Account.signIn(payload);
      window.localStorage.setItem(TOKEN_KEY, token);
      commit(MutationTypes.setToken, token);
      commit(MutationTypes.setIsLogin, true);
      await dispatch(ActionTypes.checkAdmin);
      if (state.isAdmin) {
        await dispatch(`currencyList/getCurrencies`);
        await dispatch(ActionTypes.initCurrentCurrency);
      }
      Sentry.setUser({ email: payload.mail });
    } catch (e) {
      Sentry.captureException(new Error('signin failure'));
      dispatch(`error/${ErrorActionType.pushError}`, e, { root: true });
    } finally {
      commit(MutationTypes.setLoading, { name: 'sign-in', isLoading: false });
    }
  },
  async signOut({ commit, state, dispatch }) {
    try {
      dispatch(ActionTypes.setLoading, { name: 'sign-out', isLoading: true });
      await API.Account.signOut(state.token);
      window.localStorage.removeItem(TOKEN_KEY);
      commit(MutationTypes.setToken, '');
      commit(MutationTypes.setIsLogin, false);
      commit(MutationTypes.setIsAdmin, false);
      commit(MutationTypes.setCurrentCurrency, '');
      commit(MutationTypes.setCurrentCurrencyInfo, null);
      Sentry.configureScope((scope) => scope.setUser(null));
    } catch (e) {
      dispatch(`error/${ErrorActionType.pushError}`, e, { root: true });
      Sentry.captureException(new Error('Signout failure'));
    } finally {
      dispatch(ActionTypes.setLoading, { name: 'sign-out', isLoading: false });
    }
  },
  async getTokenFromLocalStorage({ commit, dispatch }) {
    const token = window.localStorage.getItem(TOKEN_KEY);
    commit(MutationTypes.setToken, token);
  },
  async getCurrencyInfo({ rootState, commit, dispatch }) {
    try {
      const req = rootState.isAdmin
        ? API.Admin.getCurrency(rootState.currentCurrencyId)
        : API.Account.getInfo;

      const currencyInfo = (await req(rootState.token)) as CurrencyInfo;
      commit(MutationTypes.setCurrentCurrencyInfo, currencyInfo);
      Sentry.setUser({
        email: currencyInfo.mail,
        username: currencyInfo.name,
      });
    } catch (e) {
      Sentry.captureException(new Error('getCurrencyInfo failure'));
      dispatch(`error/${ErrorActionType.pushError}`, e, {
        root: true,
      });
    }
  },
  setLoading({ commit }, payload) {
    commit(MutationTypes.setLoading, payload);
  },
  changeDateRange({ commit }, payload) {
    commit(MutationTypes.setDateRange, { dateRange: payload });
  },
  setCurrentCurrency(
    { commit, dispatch },
    { id, currentRoute = '' }: { id: string; currentRoute: string }
  ) {
    commit(MutationTypes.setCurrentCurrency, id);
    dispatch(ActionTypes.initState, currentRoute);
  },
  initCurrentCurrency({ commit, dispatch }) {
    const doId = 'f1a7e4e9f001451abc71602214acc5a7'; // Doのuuid
    dispatch(ActionTypes.setCurrentCurrency, { id: doId });
  },
};

export const ActionTypes = typesEnumeration(actions);
export default actions;
