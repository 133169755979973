import request, {
  withPost,
  withToken,
  withPut,
  queryBuilder,
  withPatch,
  errorHandle,
} from './request';
import {
  object,
  string,
  array,
  number,
  dict,
  boolean,
} from '@mojotech/json-type-validation';
import {
  NotFound,
  BadRequest,
  Unauthorized,
  Forbidden,
  ServiceUnivailable,
  InternalServerError,
  loginError,
  wrongIdPass,
} from '../error';

export namespace Account {
  const tokenDecoder = object({
    token: string(),
  });
  const validTokenDecoder = object({
    isToken: boolean(),
  });
  const stringValueDecoder = object({
    value: string(),
  });
  const currencyDecoder = object({
    uuid: string(),
    name: string(),
    unit: string(),
    region: string(),
    description: string(),
  });
  const qrItemDecoder = object({
    value: string(),
    expire: boolean(),
    expiredAt: number().map((x) => new Date(x)),
    createdAt: number().map((x) => new Date(x)),
  });
  const paymentDecodr = object({
    type: string(),
    tradeType: string(),
    readType: string(),
    message: string(),
    point: number(),
    qr: object({
      issueCounts: number(),
      items: array(qrItemDecoder),
    }),
  });
  const qrDecoder = object({
    currency: currencyDecoder,
    payment: paymentDecodr,
  });
  const infoDecoder = object({
    name: string(),
    mail: string(),
    region: string(),
    description: string(),
    status: number(),
    unit: string(),
  });
  const tradeHistoryDecoder = object({
    t: string(),
    name: string(),
    date: string().map((x) => new Date(x)),
    amount: number(),
    balance: number(),
    message: string(),
  }).map((x) => ({ ...x, type: x.t }));
  const tradeNetworkDecoder = object({
    links: array(array(number())),
    nodes: array(string()),
  });
  const amountDecoder = object({
    amount: number(),
  });
  const userDecoder = object({
    id: string(),
    name: string(),
    phoneNumber: string(),
    faceImage: string(),
    mail: string(),
    qr: string(),
  });
  const nodeDecoder = object({
    s: number(),
    r: number(),
  });

  // 通貨仮登録
  export const preSignUp = (body: { email: string; terminalId: string }) =>
    request('/sign-up/pre', withPost(body))
      .then((x) => x.json())
      .then(tokenDecoder.runPromise);

  // 通貨登録
  export const signUp = (body: {
    name: string;
    email: string;
    terminalId: string;
    oneTimeToken: string;
    pinCode: string;
    region: string;
    password: string;
  }) => request('/sign-up', withPost(body));

  // サインイン
  export const signIn = (body: { mail: string; password: string }) =>
    request('/sign-in', withPost(body))
      .then(errorHandle(500, new wrongIdPass()))
      .then(errorHandle(404, new wrongIdPass()))
      .then((x) => x.json())
      .then(tokenDecoder.runPromise);

  // サインアウト
  export const signOut = (token: string) =>
    request('/sign-out', withPost({}), withToken(token))
      .then(errorHandle(400, new BadRequest()))
      .then(errorHandle(401, new loginError()));

  // トークンが有効かチェック
  export const checkToken = (token: string) =>
    request('/token', withToken(token))
      .then((x) => x.json())
      .then(validTokenDecoder.runPromise);

  // 仮パスワード変更
  export const prePasswordChange = (body: { email: string }) =>
    request('/password/pre', withPost(body))
      .then(errorHandle(400, new BadRequest('仮パスワード変更できません')))
      .then(errorHandle(401, new Unauthorized()))
      .then((x) => x.json())
      .then(stringValueDecoder.runPromise);

  // パスワードの変更
  export const passwordChange = (
    token: string,
    body: {
      password: string;
      newPassword: string;
      oneTimeToken: string;
      pinCode: string;
    }
  ) =>
    request('/password', withPut(body), withToken(token))
      .then(errorHandle(400, new BadRequest()))
      .then(errorHandle(404, new NotFound('情報')));

  // 通貨情報取得
  export const getInfo = (token: string) =>
    request('/info', withToken(token))
      .then(errorHandle(404, new NotFound('通貨情報')))
      .then((x) => x.json())
      .then(infoDecoder.runPromise);

  // 通過情報編集
  export const patchDescription = (
    token: string,
    body: { description: string }
  ) =>
    request('/info/description', withPatch(body), withToken(token))
      .then(errorHandle(400, new BadRequest('組織が存在しません')))
      .then(errorHandle(401, new Unauthorized()));

  export const patchName = (token: string, body: { name: string }) =>
    request('/info/name', withPatch(body), withToken(token))
      .then(errorHandle(400, new BadRequest('組織が存在しません')))
      .then(errorHandle(401, new Unauthorized()));

  export const patchUnit = (token: string, body: { unit: string }) =>
    request('/info/unit', withPatch(body), withToken(token))
      .then(errorHandle(400, new BadRequest('組織が存在しません')))
      .then(errorHandle(401, new Unauthorized()));

  // 通貨取引履歴取得
  export const getTradeHistory = (token: string) =>
    request('/trade-history', withToken(token))
      .then(errorHandle(400, new BadRequest('組織が存在しません')))
      .then(errorHandle(401, new Unauthorized()))
      .then(errorHandle(404, new NotFound('通貨取引履歴')))
      .then((x) => x.json())
      .then(array(tradeHistoryDecoder).runPromise);

  // 期間別流通ネットワーク
  export const getTradeNetwork = (
    token: string,
    offset: number,
    year: number,
    month?: number,
    day?: number
  ) =>
    request(
      `/trade-network?${queryBuilder({ offset, year, month, day })}`,
      withToken(token)
    )
      .then(errorHandle(404, new NotFound('流通ネットワーク')))
      .then((x) => x.json())
      .then(tradeNetworkDecoder.runPromise);

  // 通貨残高取得
  export const getAmount = (token: string) =>
    request('/amount', withToken(token))
      .then(errorHandle(404, new NotFound('通貨残高')))
      .then((x) => x.json())
      .then(amountDecoder.runPromise);

  // 通貨発行量
  export const getIssueAmount = (token: string) =>
    request('/issue-amount', withToken(token))
      .then(errorHandle(404, new NotFound('通貨発行量')))
      .then(errorHandle(503, new ServiceUnivailable()))
      .then((x) => x.json())
      .then(amountDecoder.runPromise);

  // 期間ごとの流通量取得
  export const getTradeAmount = (
    token: string,
    offset: number,
    year: number,
    month?: number,
    day?: number
  ) =>
    request(
      `/trade-amount?${queryBuilder({ offset, year, month, day })}`,
      withToken(token)
    )
      .then(errorHandle(400, new BadRequest('指定した組織が存在しません')))
      .then(errorHandle(401, new Unauthorized()))
      .then(errorHandle(404, new NotFound('流通量')))
      .then((x) => x.json())
      .then(dict(number()).runPromise);

  // ユーザ一覧
  export const getUsers = (token: string) =>
    request('/users', withToken(token))
      .then(errorHandle(404, new NotFound('ユーザーデータ')))
      .then((x) => x.json())
      .then(array(userDecoder).runPromise);

  // ユーザ一覧
  export const getUsersWithOption = (
    offset: number,
    limit: number,
    userId?: string,
    userName?: string,
    phoneNumber?: string
  ) => (token: string) =>
    request(
      `/users?${queryBuilder({
        offset,
        limit,
        userId,
        userName,
        phoneNumber,
      })}`,
      withToken(token)
    )
      .then(errorHandle(404, new NotFound('ユーザーデータ')))
      .then((x) => x.json())
      .then(array(userDecoder).runPromise);

  // ユーザー取引履歴
  export const getUserHistory = (
    token: string,
    body: {
      users: string[];
    }
  ) =>
    request('/users/history', withPost(body), withToken(token))
      .then(errorHandle(400, new BadRequest('組織が存在しません')))
      .then(errorHandle(401, new Unauthorized()))
      .then(
        errorHandle(403, new Forbidden('バリューサーバでエラーが発生しました'))
      )
      .then(errorHandle(404, new NotFound('ユーザー取引履歴')))
      .then((x) => x.json())
      .then(array(array(tradeHistoryDecoder)).runPromise);

  // 各ユーザーの取引量比率を取得する
  export const getDetailAmount = (token: string, userID: string) =>
    request(`/users/${userID}/detail-amount`, withToken(token))
      .then(errorHandle(400, new BadRequest()))
      .then(errorHandle(401, new Unauthorized()))
      .then(errorHandle(403, new Forbidden()))
      .then((x) => x.json())
      .then(nodeDecoder.runPromise);

  // ポイント送受信+QR
  const createPayment = (
    query: { paymentType: string; tradeType: number; readType: number },
    token: string,
    body: {
      issueCounts?: number;
      point: number;
      message: string;
      users?: string[];
    }
  ) =>
    request(
      `/payment?paymentType=${query.paymentType}&tradeType=${query.tradeType}&readType=${query.readType}`,
      withToken(token),
      withPost(body)
    )
      .then(errorHandle(400, new BadRequest()))
      .then(errorHandle(401, new Unauthorized()))
      .then(errorHandle(403, new Forbidden()))
      .then(errorHandle(500, new InternalServerError()));

  const createPaymentQR = (
    query: { tradeType: number; readType: number },
    token: string,
    body: {
      issueCounts: number;
      point: number;
      message: string;
      users?: string[];
    }
  ) =>
    createPayment({ ...query, paymentType: 'qr' }, token, body)
      .then((x) => x.json())
      .then(qrDecoder.runPromise);

  export const createOncePayoutQR = (
    token: string,
    body: { point: number; message: string; issueCounts: number }
  ) => createPaymentQR({ tradeType: 2, readType: 0 }, token, body);

  export const createEverPayoutQR = (
    token: string,
    body: { point: number; message: string; issueCounts: number }
  ) => createPaymentQR({ tradeType: 2, readType: 9 }, token, body);

  export const createOnceLevyQR = (
    token: string,
    body: { point: number; message: string; issueCounts: number }
  ) => createPaymentQR({ tradeType: 0, readType: 0 }, token, body);

  export const createEverLevyQR = (
    token: string,
    body: { point: number; message: string; issueCounts: number }
  ) => createPaymentQR({ tradeType: 0, readType: 9 }, token, body);

  // 一括ポイント配布
  // readType の query を入れないと404になる
  export const sendPointUsers = (
    token: string,
    body: { users: string[]; point: number; message: string }
  ) =>
    createPayment(
      { paymentType: 'bulk', tradeType: 2, readType: 0 },
      token,
      body
    );

  // 一括ポイント徴収
  // readType の query を入れないと404になる
  export const getPointUsers = (
    token: string,
    body: { users: string[]; point: number; message: string }
  ) =>
    createPayment(
      { paymentType: 'bulk', tradeType: 0, readType: 0 },
      token,
      body
    );
}
