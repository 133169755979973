import { StoreType } from '../../types';
import { Module } from 'vuex';
import actions from './actions';
import mutations from './mutation';

const state: StoreType.UserState = {
  loading: { user: false },
  users: {},
  usersTable: [],
  userTransactionRate: {},
  userSize: 0,
  offset: 0,
  limit: 20,
  pageIdx: 1,
  userId: '',
  userName: '',
  phoneNumber: '',
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
} as Module<StoreType.UserState, StoreType.RootState>;
