import { Action, StoreType, typesEnumeration } from '@/store/types';
import { MutationType } from './mutations';

type ActionType = 'pushError' | 'popError';

const actions: Action<ActionType, StoreType.ErrorState, StoreType.RootState> = {
  pushError({ commit }, error) {
    commit(MutationType.pushError, { error });
  },
  popError({ commit }) {
    commit(MutationType.popError);
  },
};

export const ActionTypes = typesEnumeration(actions);
export default actions;
