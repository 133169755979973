import { StoreType, Action, typesEnumeration } from '../../types';
import API from '../../../api';
import { MutationTypes } from './mutation';
import { ActionTypes as ErrorActionTypes } from '../error/actions';
import * as Sentry from '@sentry/browser';

type ActionType =
  | 'initState'
  | 'getUsers'
  | 'getTransactionRateById'
  | 'setLimit'
  | 'setOffset'
  | 'setPageIdx'
  | 'setUserId'
  | 'setUserName'
  | 'setPhoneNumber'
  | 'getUserWithOption'
  | 'getUserSize';

const actions: Action<ActionType, StoreType.UserState, StoreType.RootState> = {
  async initState({ dispatch, rootState }) {
    dispatch(ActionTypes.setOffset, 0);
    dispatch(ActionTypes.getUserSize, rootState.currentCurrencyId);
    dispatch(ActionTypes.getUserWithOption, rootState.currentCurrencyId);
  },
  async getUsers({ rootState, commit, dispatch }) {
    const req = rootState.isAdmin
      ? API.Admin.getUsersWithId(rootState.currentCurrencyId)
      : API.Account.getUsers;

    try {
      const users = await req(rootState.token);
      commit(MutationTypes.setUsers, users);
    } catch (e) {
      dispatch(`error/${ErrorActionTypes.pushError}`, e, {
        root: true,
      });
      Sentry.captureException(new Error('getUsers failure'));
    }
  },
  async getTransactionRateById(
    { rootState, commit, dispatch },
    userId: string
  ) {
    try {
      const req = rootState.isAdmin
        ? API.Admin.getUserDetailAmountById(rootState.currentCurrencyId)
        : API.Account.getDetailAmount;
      const rate = await req(rootState.token, userId);
      commit(MutationTypes.setUserTransactionRate, { id: userId, rate });
    } catch (e) {
      dispatch(`error/${ErrorActionTypes.pushError}`, e, {
        root: true,
      });
      Sentry.captureException(new Error('getTransactionRateById failure'));
    }
  },
  setLimit({ commit }, limit: number) {
    commit(MutationTypes.setLimit, limit);
  },
  setOffset({ commit }, offset: number) {
    commit(MutationTypes.setOffset, offset);
  },
  setPageIdx({ commit }, id: number) {
    commit(MutationTypes.setPageIdx, id);
  },
  setUserId({ commit }, userId: string) {
    commit(MutationTypes.setUserId, userId);
  },
  setUserName({ commit }, userName: string) {
    commit(MutationTypes.setUserName, userName);
  },
  setPhoneNumber({ commit }, phoneNumber: string) {
    commit(MutationTypes.setPhoneNumber, phoneNumber);
  },
  async getUserWithOption({ rootState, state, commit, dispatch }) {
    commit(MutationTypes.setUserLoading, true);
    const req = rootState.isAdmin
      ? API.Admin.getUsersWithIdAndOption(rootState.currentCurrencyId)
      : API.Account.getUsersWithOption;
    try {
      const users = await req(
        state.offset,
        state.limit,
        state.userId,
        state.userName,
        state.phoneNumber
      )(rootState.token);
      commit(MutationTypes.setUserTable, users);
    } catch (e) {
      dispatch(`error/${ErrorActionTypes.pushError}`, e, { root: true });
      Sentry.captureException(new Error('getUserWithOption failure'));
    } finally {
      commit(MutationTypes.setUserLoading, false);
    }
  },
  async getUserSize({ rootState, commit, dispatch }) {
    const req = rootState.isAdmin
      ? API.Admin.getUsersTotalwithId(rootState.currentCurrencyId)
      : API.Log.totalPeople;
    try {
      const { value: userSize } = await req(rootState.token);
      commit(MutationTypes.setUserSize, userSize);
    } catch (e) {
      dispatch(`error/${ErrorActionTypes.pushError}`, e, { root: true });
      Sentry.captureException(new Error('getUserSize failure'));
    }
  },
};

export const ActionTypes = typesEnumeration(actions);
export default actions;
