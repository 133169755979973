import request, {
  withPost,
  withToken,
  withPatch,
  withDelete,
  queryBuilder,
  errorHandle,
} from './request';
import {
  object,
  string,
  array,
  number,
  dict,
} from '@mojotech/json-type-validation';
import {
  NotFound,
  BadRequest,
  Unauthorized,
  Forbidden,
  ServiceUnivailable,
  BadGateway,
  InternalServerError,
} from '../error';

export namespace Admin {
  const tokenDecoder = object({
    value: string(),
  });
  const currencyDecoder = object({
    uuid: string(),
    name: string(),
    unit: string(),
    region: string(),
    description: string(),
    created_at: string().map((x) => new Date(x)),
  });
  const categoryDecoder = object({
    id: string(),
    name: string(),
    created_at: string().map((x) => new Date(x)),
    updated_at: string().map((x) => new Date(x)),
  });
  const regionDecoder = object({
    id: string(),
    name: string(),
    created_at: string().map((x) => new Date(x)),
    updated_at: string().map((x) => new Date(x)),
  });
  const userDecoder = object({
    id: string(),
    name: string(),
    phone_number: string(),
    face_image: string(),
    mail: string(),
    qr: string(),
  }).map((x) => ({
    ...x,
    phoneNumber: x.phone_number,
    faceImage: x.face_image,
  }));
  const tradeHistoryDecoder = object({
    type: string(),
    name: string(),
    date: string().map((x) => new Date(x)),
    amount: number(),
    message: string(),
  });
  const tradeNetworkDecoder = object({
    links: array(array(number())),
    nodes: array(string()),
  });
  const amountDecoder = object({
    value: number(),
  }).map((x) => ({ amount: x.value }));
  const nodeDecoder = object({
    s: number(),
    r: number(),
  });

  export const healthCheck = () => request('').then((x) => x.status === 200);

  export const checkIsAdmin = (token: string) =>
    request('/admin/check', withToken(token)).then((x) => x.status === 204);

  // 通貨仮登録
  export const preSignUp = (
    token: string,
    body: { email: string; terminalId: string }
  ) =>
    request('/sign-up/pre', withPost(body), withToken(token))
      .then(errorHandle(400, new BadRequest('仮パスワードが変更できません')))
      .then(errorHandle(401, new Unauthorized()))
      .then((x) => x.json())
      .then(tokenDecoder.runPromise);

  // 通貨登録
  export const signUp = (
    token: string,
    body: {
      name: string;
      description: string;
      email: string;
      terminalId: string;
      oneTimeToken: string;
      pinCode: string;
      region: string;
      password: string;
    }
  ) =>
    request('/sign-up', withPost(body), withToken(token))
      .then(errorHandle(400, new BadRequest('無効なリクエストです')))
      .then(errorHandle(404, new NotFound('情報')));

  // 全通貨一覧取得
  export const getCurrencies = (token: string) =>
    request(`/admin/currencies`, withToken(token))
      .then(errorHandle(400, new BadRequest()))
      .then(errorHandle(404, new NotFound('通貨一覧')))
      .then((x) => x.json())
      .then(array(currencyDecoder).runPromise);

  // カテゴリー一覧を取得
  export const getCategories = (token: string) =>
    request('/categories', withToken(token))
      .then(errorHandle(401, new Unauthorized()))
      .then(errorHandle(500, new InternalServerError()))
      .then((x) => x.json())
      .then(array(categoryDecoder).runPromise);

  // カテゴリー追加
  export const addCategory = (token: string, body: { name: string }) =>
    request('/category', withToken(token), withPost(body));

  // 地域一覧取得
  export const getRegions = (token: string) =>
    request('/regions', withToken(token))
      .then((x) => x.json())
      .then(array(regionDecoder).runPromise);

  export const patchRegion = (
    token: string,
    id: string,
    body: { name: string }
  ) =>
    request(`/admin/regions/${id}`, withPatch(body), withToken(token))
      .then(errorHandle(400, new BadRequest()))
      .then(errorHandle(401, new Unauthorized()))
      .then(errorHandle(403, new Forbidden()));

  // カテゴリー編集
  export const editCategory = (id: string) => (
    token: string,
    body: { name: string }
  ) => request(`/categories/${id}`, withToken(token), withPatch(body));

  // カテゴリー削除
  export const deleteCategory = (id: string) => (token: string) =>
    request(`/categories/${id}`, withToken(token), withDelete());

  // IDで通貨情報を取得
  export const getCurrency = (id: string) => (token: string) =>
    request(`/admin/currencies/${id}`, withToken(token))
      .then(errorHandle(400, new BadRequest()))
      .then(errorHandle(404, new NotFound('通貨情報')))
      .then((x) => x.json())
      .then(currencyDecoder.runPromise);

  // IDでユーザー情報を取得
  export const getUsersWithId = (id: string) => (token: string) =>
    request(`/admin/currencies/${id}/users`, withToken(token))
      .then(errorHandle(404, new NotFound('ユーザーデータ')))
      .then((x) => x.json())
      .then(array(userDecoder).runPromise);

  export const getUserDetailAmountById = (id: string) => (
    token: string,
    userId: string
  ) =>
    request(
      `/admin/currencies/${id}/users/${userId}/detail-amount`,
      withToken(token)
    )
      .then(errorHandle(400, new BadRequest()))
      .then(errorHandle(401, new Unauthorized()))
      .then(errorHandle(403, new Forbidden()))
      .then((x) => x.json())
      .then(nodeDecoder.runPromise);

  export const getUsersWithIdAndOption = (id: string) => (
    offset: number,
    limit: number,
    userId?: string,
    userName?: string,
    phoneNumber?: string
  ) => (token: string) =>
    request(
      `/admin/currencies/${id}/users?${queryBuilder({
        offset,
        limit,
        userId,
        userName,
        phoneNumber,
      })}`,
      withToken(token)
    )
      .then(errorHandle(404, new NotFound('ユーザーデータ')))
      .then((x) => x.json())
      .then(array(userDecoder).runPromise);

  // ID ユーザー数を獲得
  export const getUsersTotalwithId = (id: string) => (token: string) =>
    request(`/admin/currencies/${id}/users/total`, withToken(token))
      .then(errorHandle(404, new NotFound('ユーザー数')))
      .then(errorHandle(503, new ServiceUnivailable()))
      .then((x) => x.json())
      .then(object({ value: number() }).runPromise);

  // IDで取引履歴を取得
  export const getHistoryWithId = (id: string) => (token: string) =>
    request(`/admin/currencies/${id}/history`, withToken(token))
      .then(errorHandle(400, new BadRequest('組織が存在しません')))
      .then(errorHandle(401, new Unauthorized()))
      .then(errorHandle(404, new NotFound('通貨取引履歴')))
      .then((x) => x.json())
      .then(array(tradeHistoryDecoder).runPromise);

  // IDでユーザー取引履歴を取得
  export const getUsersHistoryWithId = (id: string) => (
    token: string,
    body: { users: string[] }
  ) =>
    request(
      `/admin/currencies/${id}/users/history`,
      withPost(body),
      withToken(token)
    )
      .then(errorHandle(400, new BadRequest('組織が存在しません')))
      .then(errorHandle(401, new Unauthorized()))
      .then(errorHandle(404, new NotFound('ユーザー取引履歴')))
      .then((x) => x.json())
      .then(array(array(tradeHistoryDecoder)).runPromise);

  // IDで通貨残高取得
  export const getAmountWithId = (id: string) => (token: string) =>
    request(`/admin/currencies/${id}/amount`, withToken(token))
      .then(errorHandle(404, new NotFound('通貨残高')))
      .then((x) => x.json())
      .then(amountDecoder.runPromise);

  // IDで通貨発行量取得
  export const getIssueAmountWithId = (id: string) => (token: string) =>
    request(`/admin/currencies/${id}/issue-amount`, withToken(token))
      .then(errorHandle(404, new NotFound('通貨発行量')))
      .then(errorHandle(502, new BadGateway()))
      .then(errorHandle(503, new ServiceUnivailable()))
      .then((x) => x.json())
      .then(amountDecoder.runPromise);

  //ID で通貨流通量取得
  export const getTradeAmountWithId = (id: string) => (
    token: string,
    offset: number,
    year: number,
    month: number,
    day: number
  ) =>
    request(
      `/admin/currencies/${id}/trade-amount?${queryBuilder({
        offset,
        year,
        month,
        day,
      })}`,
      withToken(token)
    )
      .then((x) => x.json())
      .then(dict(number()).runPromise);

  export const getTradeNetworkWithId = (id: string) => (
    token: string,
    offset: number,
    year: number,
    month: number,
    day: number
  ) =>
    request(
      `/admin/currencies/${id}/trade-network?${queryBuilder({
        offset,
        year,
        month,
        day,
      })}`,
      withToken(token)
    )
      .then(errorHandle(404, new NotFound('流通ネットワーク')))
      .then((x) => x.json())
      .then(tradeNetworkDecoder.runPromise);

  // IDで通貨名編集
  export const patchCurrencyNameWithId = (id: string) => (
    token: string,
    body: { name: string }
  ) =>
    request(`/admin/currencies/${id}/name`, withPatch(body), withToken(token))
      .then(errorHandle(400, new BadRequest('組織が存在しません')))
      .then(errorHandle(401, new Unauthorized()));

  // IDで通貨説明編集
  export const patchCurrencyDescriptionWithId = (id: string) => (
    token: string,
    body: { description: string }
  ) =>
    request(
      `/admin/currencies/${id}/description`,
      withPatch(body),
      withToken(token)
    )
      .then(errorHandle(400, new BadRequest('組織が存在しません')))
      .then(errorHandle(401, new Unauthorized()));

  export const patchCurrencyUnitWithId = (id: string) => (
    token: string,
    body: { unit: string }
  ) =>
    request(`/admin/currencies/${id}/unit`, withPatch(body), withToken(token))
      .then(errorHandle(400, new BadRequest('組織が存在しません')))
      .then(errorHandle(401, new Unauthorized()));
}
