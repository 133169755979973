import {
  Mutation,
  StoreType,
  typesEnumeration,
  TradeHistory,
  User,
} from '../../types';

type MutationType =
  | 'setUsers'
  | 'setTradeHistory'
  | 'setUserHistory'
  | 'setUserSize'
  | 'setLimit'
  | 'setOffset'
  | 'setTradeHistoryLoading'
  | 'setUserHistoryLoading';

const mutations: Mutation<MutationType, StoreType.Organization> = {
  setUsers(state, users: User[]) {
    state.users = users.reduce(
      (acc, x) => ({ ...acc, [x.id]: x }),
      {} as {
        [id: string]: User;
      }
    );
  },
  setTradeHistory(state, { tradeHistories }) {
    state.tradeHistory = tradeHistories;
  },
  setUserHistory(
    state,
    { history }: { history: (TradeHistory & { partner: string })[] }
  ) {
    state.userHistory = history;
  },
  setUserSize(state, size: number) {
    state.userSize = size;
  },
  setLimit(state, limit: number) {
    state.limit = limit;
  },
  setOffset(state, offset: number) {
    state.offset = offset;
  },
  setTradeHistoryLoading(state, isLoading: boolean) {
    state.tradeHistoryLoading = isLoading;
  },
  setUserHistoryLoading(state, isLoading: boolean) {
    state.userHistoryLoading = isLoading;
  },
};

export const MutationType = typesEnumeration(mutations);
export default mutations;
