import { locale } from 'element-ui/lib/locale';
import { Module } from 'vuex';
import { StoreType } from '../../types';
import actions from './actions';
import mutations from './mutations';

const state: StoreType.Organization = {
  userSize: 0,
  users: {},
  tradeHistory: [],
  userHistory: [],
  offset: 0,
  limit: 20,
  tradeHistoryLoading: true,
  userHistoryLoading: true,
};

const getters = {};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
} as Module<StoreType.Organization, StoreType.RootState>;
