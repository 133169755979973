<template>
  <el-header class="header">
    <div class="left">
      <slot name="left" />
      <el-breadcrumb class="breadcrumb">
        <el-breadcrumb-item v-for="item in breadcrumb" :key="item">
          {{ item }}
        </el-breadcrumb-item>
      </el-breadcrumb>
      <inline-date-picker
        v-if="hasDateRange"
        :dateRange="dateRange"
        :showError="showError"
        :errMsg="errMsg"
        @changeDate="x => $emit('changeDate', x)"
        @focus="$emit('focus')"
      />
    </div>
    <div class="right">
      <slot name="right" />
    </div>
  </el-header>
</template>

<script>
import InlineDatePicker from '@/components/InlineDatePicker.vue';
export default {
  components: { InlineDatePicker },
  props: {
    breadcrumb: {
      type: Array,
      required: true,
    },
    hasDateRange: {
      type: Boolean,
      required: true,
    },
    dateRange: {
      type: Array,
      required: true,
    },
    showError: {
      type: Boolean,
      default: false,
    },
    errMsg: {
      type: String,
      default: '',
    },
  },
};
</script>

<style scoped lang="scss">
.header {
  background: white;
  border-bottom: 1px solid #f2f2f2;
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
}
.left {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.breadcrumb {
  font-size: 1rem;
  margin-left: 1rem;
  margin-right: 1rem;
}
</style>
