<template>
  <el-form label-position="right" label-width="80px">
    <el-form-item label="期間指定" :error="errMsg" :show-message="showError">
      <el-date-picker
        class="date-picker"
        type="daterange"
        :value="dateRange"
        @input="x => $emit('changeDate', x)"
        @focus="$emit('focus')"
      />
    </el-form-item>
  </el-form>
</template>

<script>
export default {
  props: {
    dateRange: {
      type: Array,
      required: true,
    },
    showError: {
      type: Boolean,
      default: false,
    },
    errMsg: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      cardStyle: {
        display: 'flex',
        flexDirection: 'row',
        alignItem: 'center',
      },
    };
  },
};
</script>

<style lang="scss" scoped>
.date-picker-wrapper {
  margin-bottom: 20px;
}

.el-form {
  .el-form-item {
    margin-bottom: 0;
  }
}
</style>
