import API from '../../../api';
import { StoreType, Action, typesEnumeration } from '../../types';
import { MutationTypes } from './mutations';
import { ActionTypes as ErrorActionTypes } from '../error/actions';
import * as Sentry from '@sentry/browser';

type ActionType = 'getCurrencies';

const actions: Action<
  ActionType,
  StoreType.CurrencyList,
  StoreType.RootState
> = {
  async getCurrencies({ rootState, commit, dispatch }) {
    try {
      const currencies = await API.Admin.getCurrencies(rootState.token);
      commit(MutationTypes.setCurrencies, currencies);
    } catch (e) {
      // Tokenがないか、Admin通貨じゃない
      Sentry.captureException(new Error('getCurrencies failure'));
      dispatch(`error/${ErrorActionTypes.pushError}`, e, { root: true });
    }
  },
};

export const ActionTypes = typesEnumeration(actions);
export default actions;
