<template>
  <el-menu router :default-active="active">
    <div v-for="menu in menus" :key="menu.name">
      <submenu v-if="menu.children" :menu="menu" />
      <el-menu-item
        v-else
        :key="menu.name"
        :index="`${menu.path}`"
        :route="{ path: `${menu.path}`, query: $route.query }"
        :disabled="disabled(menu)"
      >
        <i :class="menu.icon" />
        <span>{{ menu.name }}</span>
      </el-menu-item>
    </div>
  </el-menu>
</template>

<script>
import Submenu from './Submenu.vue';

export default {
  components: { Submenu },
  props: {
    menus: {
      type: Array,
      required: true,
    },
    active: {
      type: String,
      required: true,
    },
  },
  methods: {
    disabled(menu) {
      if (menu.disabled === undefined) {
        return false;
      }

      if (menu.disabled instanceof 'function') {
        return menu.disabled(this.$store);
      } else {
        return menu.disabled;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.el-menu {
  border: none;
}
</style>
