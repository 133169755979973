import { StoreType } from '../../types';
import { Module } from 'vuex';
import actions from './actions';
import mutations from './mutations';

const state: StoreType.TradeNework = {
  loading: false,
  tradeNetwork: { nodes: [], links: [] },
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
} as Module<StoreType.TradeNework, StoreType.RootState>;
