import {
  StoreType,
  Mutation,
  typesEnumeration,
  Region,
  Category,
} from '../../types';

type MutationType =
  | 'setRegions'
  | 'setCategories'
  | 'setGetCategoryLoading'
  | 'setAddCategoryLoading'
  | 'setEditCategoryLoading'
  | 'setDeleteCategoryLoading'
  | 'setGetRegionLoading'
  | 'setPatchRegionLoading';

const mutations: Mutation<MutationType, StoreType.Attribute> = {
  setRegions(state, { regions }: { regions: Region[] }) {
    state.regions = [...regions].sort((a, b) => {
      return a.id < b.id ? -1 : 1;
    });
  },
  setCategories(state, { categories }: { categories: Category[] }) {
    state.categories = categories;
  },
  setGetCategoryLoading(state, payload: boolean) {
    state.loading = { ...state.loading, getCategory: payload };
  },
  setAddCategoryLoading(state, payload: boolean) {
    state.loading = { ...state.loading, addCategory: payload };
  },
  setEditCategoryLoading(state, payload: boolean) {
    state.loading = { ...state.loading, editCategory: payload };
  },
  setDeleteCategoryLoading(state, payload: boolean) {
    state.loading = { ...state.loading, deleteCategory: payload };
  },
  setGetRegionLoading(state, payload: boolean) {
    state.loading = { ...state.loading, getRegion: payload };
  },
  setPatchRegionLoading(state, payload: boolean) {
    state.loading = { ...state.loading, patchRegion: payload };
  },
};

export const MutationTypes = typesEnumeration(mutations);
export default mutations;
