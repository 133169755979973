<template>
  <el-submenu :index="menu.path" v-if="disabled">
    <template slot="title">
      <i :class="menu.icon" />
      <span slot="title">{{ menu.name }}</span>
    </template>
    <el-menu-item
      v-for="item in menu.children"
      :key="item.name"
      :index="`${menu.path}/${item.path}`"
      :route="{ path: `${menu.path}/${item.path}`, query: $route.query }"
    >
      <i :class="item.icon" />
      <span>{{ item.name }}</span>
    </el-menu-item>
  </el-submenu>
</template>

<script>
export default {
  props: {
    menu: {
      type: Object,
      required: true,
    },
  },
  computed: {
    disabled() {
      if (this.menu.disabled === undefined) {
        return false;
      }

      if (typeof this.menu.disabled === 'function') {
        return this.menu.disabled(this.$store.state);
      } else {
        return this.menu.disabled;
      }
    },
  },
};
</script>

<style></style>
