import Vue from 'vue';
import Vuex, { Store, GetterTree } from 'vuex';
import admin from './modules/admin';
import console from './modules/console';
import transfer from './modules/transfer';
import user from './modules/user';
import collection from './modules/collection';
import newCurrency from './modules/newCurrency';
import organization from './modules/organization';
import editCurrency from './modules/editCurrency';
import tradeNetwork from './modules/tradeNetwork';
import attribute from './modules/attribute';
import error from './modules/error';
import changePassword from './modules/changePassword';
import currencyList from './modules/currencyList';
import actions from './actions';
import mutations from './mutations';
import { StoreType } from './types';

Vue.use(Vuex);

const ONE_MONTH_DATE = 31 * 24 * 60 * 60 * 1000;

export const getters: GetterTree<StoreType.RootState, StoreType.RootState> = {
  calcOffset: (state) => {
    const [from, to] = state.dateRange;

    const delta = to.getTime() - from.getTime();
    const offset = delta / 1000 / 60 / 60 / 24;

    return {
      year: to.getFullYear(),
      month: to.getMonth() + 1,
      day: to.getDate(),
      offset,
    };
  },
};

export default new Store<StoreType.RootState>({
  state: {
    currentCurrencyId: '',
    currencyInfo: null,
    isAdmin: false,
    isAlive: true,
    token: '',
    isLogin: false,
    loading: {
      'sign-in': false,
      'sign-out': false,
      entry: false,
    },
    dateRange: [new Date(Date.now() - ONE_MONTH_DATE), new Date()],
  },
  getters,
  actions,
  mutations,
  modules: {
    admin,
    console,
    transfer,
    user,
    collection,
    newCurrency,
    organization,
    editCurrency,
    tradeNetwork,
    attribute,
    error,
    changePassword,
    currencyList,
  },
});
