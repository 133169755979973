import * as Vuex from 'vuex';
import { StoreType, Action, typesEnumeration } from '../../types';
import { MutationTypes } from './mutations';
import { ActionTypes as ErrorActionTypes } from '../error/actions';
import API from '@/api';
import * as Sentry from '@sentry/browser';

type ActionType =
  | 'getUsers'
  | 'getUserSize'
  | 'changeMethod'
  | 'changeQRType'
  | 'changeSelectedUsers'
  | 'changePoint'
  | 'changeMessage'
  | 'changeSheetNum'
  | 'nextState'
  | 'prevState'
  | 'reset'
  | 'getOrgAmount';

const moveState = (dir: 'prev' | 'next', state: StoreType.CollectionState) => {
  const states: {
    [key in StoreType.CollectionState]: {
      prev: StoreType.CollectionState;
      next: StoreType.CollectionState;
    };
  } = {
    SELECT_METHOD: {
      prev: 'SELECT_METHOD',
      next: 'SET_AMOUNT',
    },
    SET_AMOUNT: {
      prev: 'SELECT_METHOD',
      next: 'CHECK',
    },
    CHECK: {
      prev: 'SET_AMOUNT',
      next: 'DONE',
    },
    DONE: {
      prev: 'CHECK',
      next: 'DONE',
    },
  };

  return states[state][dir];
};

// 状態遷移時に行われる動作
const stateAction: {
  [key in StoreType.CollectionState]: (
    context: Vuex.ActionContext<StoreType.Collection, StoreType.RootState>
  ) => void;
} = {
  SELECT_METHOD() {},
  SET_AMOUNT() {},
  async CHECK({ commit, state, rootState, dispatch }) {
    switch (state.method) {
      case 'user':
        try {
          commit(MutationTypes.setSending, true);
          await API.Account.getPointUsers(rootState.token, {
            users: state.selectedUsers.map((x) => x.id),
            point: state.point,
            message: state.message,
          });
        } catch (e) {
          dispatch(`error/${ErrorActionTypes.pushError}`, e, { root: true });
          commit(MutationTypes.setIsFailed, true);
          Sentry.captureException(new Error('getPointUsers failure'));
        } finally {
          commit(MutationTypes.setSending, false);
        }
        return;
      case 'qr':
        try {
          const req =
            state.qrType === 'infinite'
              ? API.Account.createEverLevyQR
              : API.Account.createOnceLevyQR;
          const { currency, payment } = await req(rootState.token, {
            issueCounts: state.sheetNum,
            point: state.point,
            message: state.message,
          });

          commit(MutationTypes.setQR, payment.qr.items);
          commit(MutationTypes.setTargetCurrency, currency);
          commit(MutationTypes.setPayment, payment);
        } catch (e) {
          Sentry.captureException(new Error('createLevyQR failure'));
          dispatch(`error/${ErrorActionTypes.pushError}`, e, { root: true });
        }
    }
    return;
  },
  DONE() {},
};

const actions: Action<ActionType, StoreType.Collection, StoreType.RootState> = {
  async getUsers({ rootState, commit, state }) {
    try {
      const req = rootState.isAdmin
        ? API.Admin.getUsersWithIdAndOption(rootState.currentCurrencyId)
        : API.Account.getUsersWithOption;
      const users = await req(0, state.userSize)(rootState.token);
      commit(MutationTypes.setUsers, users);
    } catch (e) {
      Sentry.captureException(new Error('getUsersWithOption failure'));
      console.error(e);
    }
  },
  async getUserSize({ rootState, dispatch, commit }) {
    const req = rootState.isAdmin
      ? API.Admin.getUsersTotalwithId(rootState.currentCurrencyId)
      : API.Log.totalPeople;
    try {
      const { value: userSize } = await req(rootState.token);
      commit(MutationTypes.setUserSize, userSize);
    } catch (e) {
      Sentry.captureException(new Error('getUserSize failure'));
      dispatch(`error/${ErrorActionTypes.pushError}`, e, { root: true });
    }
  },
  async getOrgAmount({ rootState, commit, dispatch }) {
    try {
      const req = rootState.isAdmin
        ? API.Admin.getAmountWithId(rootState.currentCurrencyId)
        : API.Account.getAmount;
      const { amount } = await req(rootState.token);
      commit(MutationTypes.setOrgAmount, amount);
    } catch (e) {
      Sentry.captureException(new Error('getOrgAmount failure'));
      dispatch(`error/${ErrorActionTypes.pushError}`, e, { root: true });
    }
  },
  changeMethod({ commit }, method) {
    commit(MutationTypes.setMethod, method);
  },
  changeQRType({ commit }, type) {
    commit(MutationTypes.setQRType, type);
  },
  changeSelectedUsers({ commit }, users) {
    commit(MutationTypes.setSelectedUsers, users);
  },
  changePoint({ commit }, point) {
    commit(MutationTypes.setPoint, point);
  },
  changeMessage({ commit }, message) {
    commit(MutationTypes.setMessage, message);
  },
  changeSheetNum({ commit }, num) {
    commit(MutationTypes.setSheetNum, num);
  },
  nextState(context) {
    const { state, commit } = context;
    stateAction[state.currentState](context);
    commit(MutationTypes.setState, moveState('next', state.currentState));
  },
  prevState({ commit, state }) {
    commit(MutationTypes.setState, moveState('prev', state.currentState));
  },
  reset({ commit }) {
    commit(MutationTypes.setState, 'SELECT_METHOD');
    commit(MutationTypes.setMethod, 'user');
    commit(MutationTypes.setQRType, 'infinite');
    commit(MutationTypes.setPoint, 1);
    commit(MutationTypes.setMessage, '');
    commit(MutationTypes.setSheetNum, 1);
    commit(MutationTypes.setSelectedUsers, []);
    commit(MutationTypes.setSending, false);
    commit(MutationTypes.setQR, []);
    commit(MutationTypes.setIsFailed, false);
  },
};

export const ActionTypes = typesEnumeration(actions);
export default actions as Vuex.ActionTree<
  StoreType.Collection,
  StoreType.RootState
>;
