import { Account } from '../../../api/account';
import { Action, StoreType, typesEnumeration } from '../../types';
import { MutationTypes } from './mutation';
import { ActionTypes as ErrorActionTypes } from '../error/actions';
import * as Sentry from '@sentry/browser';

type ActionType =
  | 'setEmail'
  | 'setOldPassword'
  | 'setNewPassword'
  | 'setOneTimeToken'
  | 'setPinCode'
  | 'nextState'
  | 'reset'
  | 'prePasswordChange'
  | 'passwordChange'
  | 'setPreChangeLoading'
  | 'setChangeLoading';

const actions: Action<
  ActionType,
  StoreType.ChangePassword,
  StoreType.RootState
> = {
  setEmail({ commit }, email) {
    commit(MutationTypes.setEmail, { email });
  },
  setOldPassword({ commit }, oldPassword) {
    commit(MutationTypes.setOldPassword, { oldPassword });
  },
  setNewPassword({ commit }, newPassword) {
    commit(MutationTypes.setNewPassword, { newPassword });
  },
  setOneTimeToken({ commit }, oneTimeToken) {
    commit(MutationTypes.setOneTimeToken, { oneTimeToken });
  },
  setPinCode({ commit }, pinCode) {
    commit(MutationTypes.setPinCode, { pinCode });
  },
  nextState({ commit, state }) {
    commit(MutationTypes.setState, { state: calcNextState(state.state) });
  },
  setPreChangeLoading({ commit }, bool: boolean) {
    commit(MutationTypes.setLoading, { state: 'inputEmail', bool: bool });
  },
  setChangeLoading({ commit }, bool: boolean) {
    commit(MutationTypes.setLoading, { state: 'inputInfo', bool: bool });
  },
  reset({ commit, dispatch }) {
    dispatch(ActionTypes.setEmail, '');
    dispatch(ActionTypes.setOldPassword, '');
    dispatch(ActionTypes.setNewPassword, '');
    dispatch(ActionTypes.setOneTimeToken, '');
    dispatch(ActionTypes.setPinCode, '');
    dispatch(ActionTypes.setPreChangeLoading, false);
    dispatch(ActionTypes.setChangeLoading, false);
    commit(MutationTypes.setState, { state: 'INPUT_EMAIL' });
  },
  async prePasswordChange({ dispatch, state, commit }) {
    try {
      dispatch(ActionTypes.setPreChangeLoading, true);
      const { value } = await Account.prePasswordChange({ email: state.email });
      dispatch(ActionTypes.setOneTimeToken, value);
      dispatch(ActionTypes.nextState);
    } catch (e) {
      Sentry.captureException(new Error('prePasswordChange failure'));
      commit(`error/${ErrorActionTypes.pushError}`, e, { root: true });
    } finally {
      dispatch(ActionTypes.setPreChangeLoading, false);
    }
  },
  async passwordChange({ dispatch, state, rootState, commit }) {
    try {
      dispatch(ActionTypes.setChangeLoading, true);
      await Account.passwordChange(rootState.token, {
        password: state.oldPassword,
        newPassword: state.newPassword,
        oneTimeToken: state.oneTimeToken,
        pinCode: state.pinCode,
      });
      dispatch(ActionTypes.nextState);
    } catch (e) {
      Sentry.captureException(new Error('passwordChange failure'));
      commit(`error/${ErrorActionTypes.pushError}`, e, { root: true });
    } finally {
      dispatch(ActionTypes.setChangeLoading, false);
    }
  },
};

const calcNextState = (
  state: StoreType.ChangePasswordState
): StoreType.ChangePasswordState => {
  switch (state) {
    case 'INPUT_EMAIL':
      return 'INPUT_INFO';
    case 'INPUT_INFO':
      return 'SUCCESS';
    case 'SUCCESS':
      return 'SUCCESS';
  }
};

export const ActionTypes = typesEnumeration(actions);
export default actions;
