import Vue from 'vue';
import ElementUI from 'element-ui';
import lang from 'element-ui/lib/locale/lang/ja';
import locale from 'element-ui/lib/locale';
import 'normalize.css/normalize.css';
import './styles/index.scss';
import App from './App.vue';
import router from './router';
import VeLine from 'v-charts/lib/line.common';
import store from './store';
import * as Sentry from '@sentry/browser';
import { Vue as VueIntegration } from '@sentry/integrations';
import { Integrations } from '@sentry/tracing';

Sentry.init({
  dsn:
    'https://4b6c7cbc73034e5180e0fac50349dc4d@o444341.ingest.sentry.io/5494160',
  integrations: [
    new Integrations.BrowserTracing(),
    new VueIntegration({
      Vue,
      tracing: true,
    }),
  ],
  environment: process.env.NODE_ENV,
  tracesSampler: () => {
    // production環境のみログを送信
    if (process.env.NODE_ENV === 'production') {
      return 1.0;
    } else {
      return 0;
    }
  },
});

locale.use(lang);

Vue.use(ElementUI);
Vue.use(VeLine.name, VeLine);

Vue.config.productionTip = false;

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount('#app');
