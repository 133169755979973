import { StoreType, Mutation, typesEnumeration } from '../../types';

type MutationType = 'setTradeNetwork' | 'setLoading';

const mutations: Mutation<MutationType, StoreType.TradeNework> = {
  setTradeNetwork(state, payload) {
    state.tradeNetwork = payload;
  },
  setLoading(state, payload) {
    state.loading = payload;
  },
};

export const MutationTypes = typesEnumeration(mutations);
export default mutations;
