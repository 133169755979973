import { StoreType } from '@/store/types';
import mutations from './mutations';
import actions from './actions';
import { Module } from 'vuex';

const state: StoreType.NewCurrency = {
  loading: {
    prePost: false,
    post: false,
  },
  pageState: 'PRE_REGISTRATION',
  oneTimeToken: '',
  categories: [],
  regions: [],
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
} as Module<StoreType.NewCurrency, StoreType.RootState>;
