import config from './config';
import { Error as ErrorType, DefaultError } from '../error';

type WithFunc = (opts: RequestInit) => RequestInit;

export default (path: string, ...withFunc: WithFunc[]) =>
  fetch(
    `${config}${path}`,
    withFunc.reduce((acc, func) => func(acc), {})
  )
    // ネットワークエラーをハンドリングしたい
    .catch(e => networkErrorHandle(e));

export const withToken = (token: string): WithFunc => opts => ({
  ...opts,
  headers: { ...(opts.headers || {}), Authorization: `Bearer ${token}` },
});

const withMethod = (method: string): WithFunc => opts => ({
  ...opts,
  method,
});

const withBody = (method: string) => <T = {}>(body: T): WithFunc => opts => ({
  ...opts,
  headers: { ...(opts.headers || {}), 'content-type': 'application/json' },
  method: method,
  body: JSON.stringify(body),
});

export const withPost = withBody('POST');
export const withPut = withBody('PUT');
export const withPatch = withBody('PATCH');
export const withDelete = () => withMethod('DELETE');

export const queryBuilder = (values: { [key in string]: string | number }) =>
  Object.entries(values)
    .filter(([_, v]) => v !== undefined)
    .map(([key, val]) => `${key}=${encodeURIComponent(val)}`)
    .join('&');

export const errorHandle = (statusCode: number, error: ErrorType) => (
  res: Response
) => {
  if (res.status === statusCode) {
    throw error;
  }

  return res;
};

export const networkErrorHandle = (error: Error) => {
  throw new DefaultError(error.message);
};
