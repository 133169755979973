import actions from './actions';
import mutations from './mutation';
import { Module, GetterTree } from 'vuex';
import { StoreType } from '../../types';

// state
const state: StoreType.Admin = {
  categories: [],
  regions: [],
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
} as Module<StoreType.Admin, StoreType.RootState>;
