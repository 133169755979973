import API from '../../../api';
import { StoreType, Action, typesEnumeration } from '../../types';
import { MutationTypes } from './mutation';
import { ActionTypes as ErrorActionTypes } from '../error/actions';
import * as Sentry from '@sentry/browser';

type ActionType =
  | 'initState'
  | 'getTraderLog'
  | 'getTransactionLog'
  | 'getIssueAmountLog'
  | 'getSupplyAmountLog'
  | 'getAverageDegreeLog'
  | 'getAverageClusterCoefficientLog'
  | 'getExponentRatioLog'
  | 'getAverageTravelDistance'
  | 'getAllLog'
  | 'getInfo'
  | 'getUserNum'
  | 'getTotalAmount'
  | 'getTotalIssueAmount';

// actions
const actions: Action<ActionType, StoreType.Console, StoreType.RootState> = {
  async initState({ dispatch, rootState, rootGetters }) {
    dispatch(ActionTypes.getInfo);
    dispatch(ActionTypes.getAllLog, {
      ...rootGetters.calcOffset,
      id: rootState.currentCurrencyId,
    });
  },
  async getTraderLog(
    { commit, rootState, dispatch },
    { offset, year, month, day }
  ) {
    try {
      const req = rootState.isAdmin
        ? API.AdminLog.getTradeLogWithId(rootState.currentCurrencyId)
        : API.Log.getTradeLog;
      const logs = await req(rootState.token, offset, year, month, day);

      commit(MutationTypes.setTraderLog, logs);
    } catch (e) {
      Sentry.captureException(new Error('getTraderLog'));
      dispatch(`error/${ErrorActionTypes.pushError}`, e, { root: true });
    }
  },
  async getTransactionLog(
    { commit, rootState, dispatch },
    { offset, year, month, day }
  ) {
    try {
      const req = rootState.isAdmin
        ? API.AdminLog.getTransactionLogWithId(rootState.currentCurrencyId)
        : API.Log.getTransactionLog;
      const logs = await req(rootState.token, offset, year, month, day);

      commit(MutationTypes.setTransactionLog, logs);
    } catch (e) {
      Sentry.captureException(new Error('getTransactionLog failure'));
      dispatch(`error/${ErrorActionTypes.pushError}`, e, { root: true });
    }
  },
  async getIssueAmountLog(
    { commit, rootState, dispatch },
    { offset, year, month, day }
  ) {
    try {
      const req = rootState.isAdmin
        ? API.AdminLog.getIssueAmountLogWithId(rootState.currentCurrencyId)
        : API.Log.getIssueAmountLog;
      const logs = await req(rootState.token, offset, year, month, day);

      commit(MutationTypes.setIssueAmount, logs);
    } catch (e) {
      Sentry.captureException(new Error('getIssueAmountLog failure'));
      dispatch(`error/${ErrorActionTypes.pushError}`, e, { root: true });
    }
  },
  async getSupplyAmountLog(
    { commit, rootState, dispatch },
    { offset, year, month, day }
  ) {
    try {
      const req = rootState.isAdmin
        ? API.AdminLog.getSupplyAmountLogWithId(rootState.currentCurrencyId)
        : API.Log.getSupplyAmountLog;
      const logs = await req(rootState.token, offset, year, month, day);
      commit(MutationTypes.setSupplyAmountLog, logs);
    } catch (e) {
      Sentry.captureException(new Error('getSupplyAmountLog failure'));
      dispatch(`error/${ErrorActionTypes.pushError}`, e, { root: true });
    }
  },
  async getAverageDegreeLog(
    { commit, rootState, dispatch },
    { offset, year, month, day }
  ) {
    try {
      const req = rootState.isAdmin
        ? API.AdminLog.getAverageDegreeLogWithId(rootState.currentCurrencyId)
        : API.Log.getAverageDegreeLog;
      const logs = await req(rootState.token, offset, year, month, day);
      commit(MutationTypes.setAverageDegreeLog, logs);
    } catch (e) {
      Sentry.captureException(new Error('getAverageDegreeLog failure'));
      dispatch(`error/${ErrorActionTypes.pushError}`, e, { root: true });
    }
  },
  async getAverageClusterCoefficientLog(
    { commit, rootState, dispatch },
    { offset, year, month, day }
  ) {
    try {
      const req = rootState.isAdmin
        ? API.AdminLog.getAverageClusterCoefficientLogWithId(
            rootState.currentCurrencyId
          )
        : API.Log.getAverageClusterCoefficientLog;
      const logs = await req(rootState.token, offset, year, month, day);
      commit(MutationTypes.setAverageClusterCoefficientLog, logs);
    } catch (e) {
      Sentry.captureException(
        new Error('getAverageClussssterCoefficientLog failure')
      );
      dispatch(`error/${ErrorActionTypes.pushError}`, e, { root: true });
    }
  },
  async getExponentRatioLog(
    { commit, rootState, dispatch },
    payload: { offset: number; year: number; month: number; day: number }
  ) {
    try {
      const req = rootState.isAdmin
        ? API.AdminLog.getExponentRatioLogWithId(rootState.currentCurrencyId)
        : API.Log.getExponentRatioLog;
      const logs = await req(
        rootState.token,
        payload.offset,
        payload.year,
        payload.month,
        payload.day
      );
      commit(MutationTypes.setExponentRatio, logs);
    } catch (e) {
      Sentry.captureException(new Error('getExponentRatioLog failure'));
      dispatch(`error/${ErrorActionTypes.pushError}`, e, { root: true });
    }
  },
  async getAverageTravelDistance(
    { commit, rootState, dispatch },
    payload: { offset: number; year: number; month: number; day: number }
  ) {
    try {
      const req = rootState.isAdmin
        ? API.AdminLog.getAverageTravelDistanceLogWithId(
            rootState.currentCurrencyId
          )
        : API.Log.getExponentRatioLog;
      const logs = await req(
        rootState.token,
        payload.offset,
        payload.year,
        payload.month,
        payload.day
      );
      commit(MutationTypes.setAverageTravelDistance, logs);
    } catch (e) {
      Sentry.captureException(new Error('getAverageTravelDistance failure'));
      dispatch(`error/${ErrorActionTypes.pushError}`, e, { root: true });
    }
  },
  async getAllLog(
    { dispatch, commit },
    payload: { year: number; month: number; day: number; offset: number }
  ) {
    commit(MutationTypes.setLogsLoading, true);
    await Promise.all([
      dispatch(ActionTypes.getAverageClusterCoefficientLog, payload),
      dispatch(ActionTypes.getAverageDegreeLog, payload),
      dispatch(ActionTypes.getIssueAmountLog, payload),
      dispatch(ActionTypes.getSupplyAmountLog, payload),
      dispatch(ActionTypes.getTraderLog, payload),
      dispatch(ActionTypes.getTransactionLog, payload),
      dispatch(ActionTypes.getExponentRatioLog, payload),
      dispatch(ActionTypes.getAverageTravelDistance, payload),
    ]);
    commit(MutationTypes.setLogsLoading, false);
  },
  async getInfo({ rootState, dispatch, commit }) {
    commit(MutationTypes.setInfoLoading, true);
    await Promise.all([
      dispatch(ActionTypes.getTotalAmount, rootState.currentCurrencyId),
      dispatch(ActionTypes.getTotalIssueAmount, rootState.currentCurrencyId),
      dispatch(ActionTypes.getUserNum),
    ]);
    commit(MutationTypes.setInfoLoading, false);
  },
  async getUserNum({ commit, rootState, dispatch }) {
    try {
      const req = rootState.isAdmin
        ? API.Admin.getUsersTotalwithId(rootState.currentCurrencyId)
        : API.Log.totalPeople;
      const { value } = await req(rootState.token);
      commit(MutationTypes.setUserNum, value);
    } catch (e) {
      Sentry.captureException(new Error('getUserNum failure'));
      dispatch(`error/${ErrorActionTypes.pushError}`, e, { root: true });
    }
  },
  async getTotalAmount({ commit, rootState, dispatch }) {
    try {
      const req = rootState.isAdmin
        ? API.Admin.getAmountWithId(rootState.currentCurrencyId)
        : API.Account.getAmount;
      const { amount } = await req(rootState.token);
      commit(MutationTypes.setTotalAmount, amount);
    } catch (e) {
      Sentry.captureException(new Error('getTotalAmount failure'));
      dispatch(`error/${ErrorActionTypes.pushError}`, e, { root: true });
    }
  },
  async getTotalIssueAmount({ commit, rootState, dispatch }) {
    try {
      const issueAmountReq = rootState.isAdmin
        ? API.Admin.getIssueAmountWithId(rootState.currentCurrencyId)
        : API.Account.getIssueAmount;
      const { amount } = await issueAmountReq(rootState.token);
      commit(MutationTypes.setTotalIssueAmount, amount);
    } catch (e) {
      Sentry.captureException(new Error('getTotalIssueAmount failure'));
      dispatch(`error/${ErrorActionTypes.pushError}`, e, { root: true });
    }
  },
};

export const ActionTypes = typesEnumeration(actions);
export default actions;
