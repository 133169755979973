import { Log, Mutation, StoreType, typesEnumeration } from '../../types';

type MutationType =
  | 'setTraderLog'
  | 'setTransactionLog'
  | 'setAverageDegreeLog'
  | 'setSupplyAmountLog'
  | 'setAverageClusterCoefficientLog'
  | 'setIssueAmount'
  | 'setUserNum'
  | 'setTotalAmount'
  | 'setTotalIssueAmount'
  | 'setExponentRatio'
  | 'setAverageTravelDistance'
  | 'setLogsLoading'
  | 'setInfoLoading';

const mutations: Mutation<MutationType, StoreType.Console> = {
  setTraderLog(state, logs) {
    state.trader = logs;
  },
  setTransactionLog(state, logs) {
    state.transaction = logs;
  },
  setAverageDegreeLog(state, logs) {
    state.averageDegree = logs;
  },
  setSupplyAmountLog(state, logs) {
    state.supplyAmount = logs;
  },
  setAverageClusterCoefficientLog(state, logs) {
    state.averageClusterCoefficient = logs;
  },
  setIssueAmount(state, logs) {
    state.issueAmount = logs;
  },
  setUserNum(state, num) {
    state.userNum = num;
  },
  setTotalIssueAmount(state, num: number) {
    state.totalAmount = num;
  },
  setTotalAmount(state, num: number) {
    state.totalIssueAmount = num;
  },
  setExponentRatio(state, logs: Log[]) {
    state.exponentRatio = logs;
  },
  setAverageTravelDistance(state, logs: Log[]) {
    state.averageTravelDistance = logs;
  },
  setLogsLoading(state, bool: boolean) {
    state.loading = { ...state.loading, logs: bool };
  },
  setInfoLoading(state, bool: boolean) {
    state.loading = { ...state.loading, info: bool };
  },
};

export const MutationTypes = typesEnumeration(mutations);
export default mutations;
