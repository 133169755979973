import { StoreType } from '../../types';
import mutations from './mutations';
import actions from './actions';
import { Module, GetterTree, Store } from 'vuex';

const state: StoreType.Collection = {
  users: [],
  userSize: 0,
  currentState: 'SELECT_METHOD',
  method: 'qr',
  qrType: 'infinite',
  selectedUsers: [],
  point: 1,
  message: '',
  sheetNum: 1,
  sending: false,
  qrSrc: '',
  isFailed: false,
  orgAmount: 0,
  targetCurrency: null,
  payment: null,
  qrs: [],
};

const getters: GetterTree<StoreType.Collection, StoreType.RootState> = {
  usersList: (state) => Object.values(state.users),
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
} as Module<StoreType.Collection, StoreType.RootState>;
