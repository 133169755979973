import { StoreType } from '../../types';
import { Module } from 'vuex';
import mutations from './mutations';
import actions from './actions';

const state: StoreType.EditCurrency = {
  loading: false,
  isFailed: false,
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
} as Module<StoreType.EditCurrency, StoreType.RootState>;
