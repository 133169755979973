import API from '../../../api';
import { StoreType, Action, typesEnumeration } from '../../types';
import { MutationTypes } from './mutation';
import { ActionTypes as ErrorActionTypes } from '../error/actions';
import * as Sentry from '@sentry/browser';

type ActionType =
  | 'getCategories'
  | 'addCategory'
  | 'editCategory'
  | 'deleteCategory'
  | 'getRegions'
  | 'patchRegion';

const actions: Action<ActionType, StoreType.Admin, StoreType.RootState> = {
  async getCategories({ rootState, commit, dispatch }) {
    try {
      const categories = await API.Admin.getCategories(rootState.token);
      commit(MutationTypes.setCategories, { categories });
    } catch (e) {
      Sentry.captureException(new Error('getCategories failure'));
      dispatch(`error/${ErrorActionTypes.pushError}`, e, { root: true });
    }
  },
  async addCategory({ rootState, dispatch }, category) {
    try {
      await API.Admin.addCategory(rootState.token, category);
      dispatch(ActionTypes.getCategories);
    } catch (e) {
      Sentry.captureException(new Error('addCategories failure'));
      dispatch(`error/${ErrorActionTypes.pushError}`, e, { root: true });
    }
  },
  async getRegions({ rootState, commit, dispatch }) {
    try {
      const regions = await API.Admin.getRegions(rootState.token);
      commit(MutationTypes.setRegions, { regions });
    } catch (e) {
      Sentry.captureException(new Error('getRegions failure'));
      dispatch(`error/${ErrorActionTypes.pushError}`, e, { root: true });
    }
  },
  async patchRegion({ rootState, dispatch }, { id, name }) {
    try {
      await API.Admin.patchRegion(rootState.token, id, { name: name });
      dispatch(ActionTypes.getRegions);
    } catch (e) {
      Sentry.captureException(new Error('patchRegion failure'));
      dispatch(`error/${ErrorActionTypes.pushError}`, e, { root: true });
    }
  },
  async editCategory(
    { rootState, dispatch },
    { id, name }: { id: string; name: string }
  ) {
    try {
      await API.Admin.editCategory(id)(rootState.token, { name });
      dispatch(ActionTypes.getCategories);
    } catch (e) {
      Sentry.captureException(new Error('editCategory failure'));
      dispatch(`error/${ErrorActionTypes.pushError}`, e, { root: true });
    }
  },
  async deleteCategory({ rootState, dispatch }, id: string) {
    try {
      await API.Admin.deleteCategory(id)(rootState.token);
      dispatch(ActionTypes.getCategories);
    } catch (e) {
      Sentry.captureException(new Error('deleteCategory failure'));
      dispatch(`error/${ErrorActionTypes.pushError}`, e, { root: true });
    }
  },
};

export const ActionTypes = typesEnumeration(actions);
export default actions;
