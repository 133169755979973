<template>
  <el-header class="header" :height="`${height}px`">
    <div class="left">
      <slot name="left" />
    </div>
    <div class="center">
      <el-form>
        <el-form-item class="x-margin">
          <el-select
            :value="currentCurrencyId"
            filterable
            @change="changeSelect"
          >
            <el-option
              v-for="currency in currencyList"
              :key="currency.uuid"
              :value="currency.uuid"
              :label="currency.name"
            />
          </el-select>
        </el-form-item>
      </el-form>
      <inline-date-picker
        v-if="hasDateRange"
        :dateRange="dateRange"
        :showError="showError"
        :errMsg="errMsg"
        @changeDate="x => $emit('changeDate', x)"
        @focus="$emit('focus')"
      />
    </div>
    <div class="right">
      <slot name="right" />
    </div>
  </el-header>
</template>

<script>
import InlineDatePicker from '@/components/InlineDatePicker.vue';
export default {
  props: {
    currencyList: {
      type: Array,
      required: true,
    },
    currentCurrencyId: {
      type: String,
      required: true,
    },
    hasDateRange: {
      type: Boolean,
      required: true,
    },
    dateRange: {
      type: Array,
      required: true,
    },
    showError: {
      type: Boolean,
      default: false,
    },
    errMsg: {
      type: String,
      default: '',
    },
    width: {
      type: Number,
      required: true,
    },
  },
  components: { InlineDatePicker },
  data() {
    return {
      search: '',
    };
  },
  methods: {
    querySearch(queryString, cb) {
      cb(
        queryString
          ? this.currencyList.filter(this.filter(queryString))
          : this.currencyList
      );
    },
    filter(queryString) {
      return target =>
        target.name.toLowerCase().indexOf(queryString.toLowerCase()) === 0;
    },
    changeSelect(el) {
      this.$emit('select', el);
    },
    selectFromCandidates(el) {
      this.$emit('select', el.uuid);
    },
  },
  computed: {
    height() {
      return this.width < 943 && this.hasDateRange ? 110 : 60;
    },
  },
};
</script>

<style scoped lang="scss">
.header {
  background: white;
  padding: 10px 20px;
  border-bottom: 1px solid #f2f2f2;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.left {
  display: flex;
}
.center {
  height: 100%;
  display: flex;
  flex-grow: 1;
  flex-wrap: wrap;
  align-content: space-between;
}

.x-margin {
  margin: 0 10px 0 10px;
}
</style>
