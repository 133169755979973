import { Mutation, StoreType, typesEnumeration } from '../../../store/types';

type MutationType = 'setIsFailed' | 'setLoading';

const mutations: Mutation<MutationType, StoreType.EditCurrency> = {
  setIsFailed(state, isFailed: boolean) {
    state.isFailed = isFailed;
  },
  setLoading(state, isLoading: boolean) {
    state.loading = isLoading;
  },
};

export const MutationType = typesEnumeration(mutations);
export default mutations;
