import LayoutWithSidebar from '@/layouts/WithSidebar';

const withSidebar = page => ({
  props: ['menus', 'breadcrumb', 'active', 'hasDateRange'],
  render(h) {
    return h(
      LayoutWithSidebar,
      {
        props: {
          menus: this.menus,
          breadcrumb: this.breadcrumb,
          active: this.active,
          hasDateRange: this.hasDateRange,
        },
      },
      [h(page)]
    );
  },
});

const sidebarProps = hasDateRange => r => ({
  menus: () => sideBarConfig,
  breadcrumb: r.matched.map(x => x.name),
  active: r.path,
  hasDateRange,
});

export const sideBarConfig = [
  {
    path: '/graph',
    name: 'ダッシュボード',
    icon: 'el-icon-s-data',
    component: withSidebar(() => import('@/pages/currency/Graph')),
    props: sidebarProps(true),
  },
  {
    path: '/network',
    name: '流通状況',
    icon: 'el-icon-share',
    component: withSidebar(() => import('@/pages/currency/Network')),
    props: sidebarProps(true),
  },
  {
    path: '/history',
    name: '取引履歴',
    icon: 'el-icon-s-management',
    component: withSidebar(() => import('@/pages/currency/Organization')),
    props: sidebarProps(true),
  },
  {
    path: '/user',
    name: 'ユーザー一覧',
    icon: 'el-icon-user-solid',
    component: withSidebar(() => import('@/pages/currency/User')),
    props: sidebarProps(true),
  },
  {
    path: '/edit-currency',
    name: '通貨編集',
    icon: 'el-icon-edit',
    component: withSidebar(() => import('@/pages/currency/EditCurrency')),
    props: sidebarProps(false),
  },
  {
    path: '/password/change',
    name: 'パスワード変更',
    icon: 'el-icon-key',
    component: withSidebar(() => import('@/pages/changePassword')),
    props: sidebarProps(false),
  },
  {
    path: '/admin',
    name: '全体通貨管理',
    icon: 'el-icon-user',
    component: LayoutWithSidebar,
    props: sidebarProps(false),
    disabled: state => state.isAdmin,
    children: [
      {
        path: 'list',
        name: '通貨一覧',
        icon: 'el-icon-s-order',
        component: () => import('@/pages/admin/List'),
      },
      {
        path: 'new-currency',
        name: '新規通貨登録',
        icon: 'el-icon-edit-outline',
        component: () => import('@/pages/admin/NewCurrency'),
      },
      {
        path: 'attributes',
        name: '地域・カテゴリー',
        icon: 'el-icon-price-tag',
        component: () => import('@/pages/admin/CategoryRegion'),
      },
      {
        path: `?external=${encodeURIComponent(
          'http://grafana.api-cs.wallet.sc/grafana'
        )}`,
        name: 'Grafana',
        icon: 'el-icon-data-analysis',
      },
    ],
  },
];

const initQuery = () => {
  const query = [...new URLSearchParams(location.search).entries()].reduce(
    (acc, [key, value]) => ({ ...acc, [key]: value }),
    {}
  );
  // vue-routerでリダイレクトするとゴミが入るのでreplaceしてる
  history.replaceState(null, null, '/');
  return query;
};

export const routerConfig = [
  ...sideBarConfig,
  {
    path: '/',
    redirect: { path: '/graph', query: initQuery() },
  },
  {
    path: '/login',
    name: 'login',
    component: () => import('@/pages/Login'),
  },
  {
    path: '/mentainance',
    name: 'mentainance',
    component: () => import('@/pages/Mentainance'),
  },
  {
    path: '*',
    name: 'not-found',
    component: withSidebar(() => import('@/pages/NotFound')),
    props: sidebarProps(false),
  },
];
