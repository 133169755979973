import actions from './actions';
import mutations from './mutations';
import { Module, GetterTree } from 'vuex';
import { StoreType, Currency } from '../../types';
import { compareRegion } from '../../../assets/regionWeight';

const state: StoreType.CurrencyList = {
  currencies: {},
};

//getters
const getters: GetterTree<StoreType.CurrencyList, StoreType.RootState> = {
  currencyList: state => Object.values(state.currencies),
  currencyListByDate: (state, getters) =>
    [...getters.currencyList].sort((a: Currency, b: Currency) =>
      a['created_at'] > b['created_at'] ? -1 : 1
    ),
  currencyListByRegion: (state, getters) =>
    [...getters.currencyList].sort((a: Currency, b: Currency) =>
      compareRegion(a.region, b.region)
    ),
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
} as Module<StoreType.CurrencyList, StoreType.RootState>;
