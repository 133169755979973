import * as Vuex from 'vuex';
import { Error } from '../error';

export type User = {
  id: string;
  name: string;
  phoneNumber: string;
  faceImage: string;
  mail: string;
  qr: string;
};

export type CurrencyInfo = {
  name: string;
  mail: string;
  region: string;
  description: string;
  status: number;
  unit: string;
  amount: number;
  issueAmount: number;
};

export type TradeHistory = {
  type: string;
  name: string;
  date: Date;
  amount: number;
  balance?: number;
  message: string;
};

export type TransactionRate = {
  s: number;
  t: number;
};

export type Currency = {
  uuid: string;
  name: string;
  unit: string;
  region: string;
  description: string;
  created_at: string;
};

export type Log = {
  date: Date;
  value: number;
};

export type NetWork = {
  links: number[];
  nodes: string[];
};

export type Category = {
  id: string;
  name: string;
  created_at: Date;
  updated_at: Date;
};
export type Region = {
  id: string;
  name: string;
  created_at: Date;
  updated_at: Date;
};

export type QRItem = {
  value: string;
  expire: boolean;
  expiredAt: Date;
  createdAt: Date;
};
export type Payment = {
  message: string;
  point: number;
  readType: string;
  tradeType: string;
  type: string;
};

export type Action<A extends string, S, R = S> = {
  [key in A]: Vuex.Action<S, R>;
};
export type Mutation<M extends string, S> = { [key in M]: Vuex.Mutation<S> };

export const typesEnumeration = <T extends string>(
  obj: { [key in T]: any },
  prefix?: string
) =>
  Object.keys(obj).reduce(
    (acc, x) => ({ ...acc, [x]: prefix ? `${prefix}/${x}` : x }),
    {} as { [key in T]: string }
  );

export namespace StoreType {
  export type RootState = {
    currentCurrencyId: string;
    currencyInfo: CurrencyInfo;
    isAdmin: boolean;
    isAlive: boolean;
    token: string;
    isLogin: boolean;
    loading: { [key in string]: boolean };
    dateRange: [Date, Date];
  };
  export type Admin = {
    categories: Category[];
    regions: Region[];
  };
  export type Console = {
    loading: { [key: string]: boolean };
    totalAmount: number;
    totalIssueAmount: number;
    userNum: number;
    trader: Log[];
    transaction: Log[];
    averageDegree: Log[];
    supplyAmount: Log[];
    averageClusterCoefficient: Log[];
    issueAmount: Log[];
    exponentRatio: Log[];
    averageTravelDistance: Log[];
  };

  export type UserState = {
    loading: { [id: string]: boolean };
    users: { [id: string]: User };
    usersTable: { [id: string]: User }[];
    userTransactionRate: { [id: string]: TransactionRate };
    offset: number;
    limit: number;
    pageIdx: number;
    userSize: number;
    userId: string;
    userName: string;
    phoneNumber: string;
  };

  export type Organization = {
    userSize: number;
    users: { [id: string]: User };
    tradeHistory: TradeHistory[];
    userHistory: (TradeHistory & { partner: string })[];
    offset: number;
    limit: number;
    tradeHistoryLoading: boolean;
    userHistoryLoading: boolean;
  };

  export type TransferState = 'SELECT_METHOD' | 'SET_AMOUNT' | 'CHECK' | 'DONE';
  export type Transfer = {
    users: User[];
    userSize: number;
    currentState: TransferState;
    method: 'user' | 'qr';
    qrType: 'onetime' | 'infinite';
    selectedUsers: User[];
    amount: number;
    message: string;
    sheetNum: number;
    sending: boolean;
    qrSrc: string;
    isFailed: boolean;
    orgAmount: number;
    targetCurrency: Currency;
    payment: Payment;
    qrs: QRItem[];
  };
  export type CollectionState =
    | 'SELECT_METHOD'
    | 'SET_AMOUNT'
    | 'CHECK'
    | 'DONE';
  export type Collection = {
    users: User[];
    userSize: number;
    currentState: CollectionState;
    method: 'user' | 'qr';
    qrType: 'onetime' | 'infinite';
    selectedUsers: User[];
    point: number;
    message: string;
    sheetNum: number;
    sending: boolean;
    qrSrc: string;
    isFailed: boolean;
    orgAmount: number;
    targetCurrency: Currency;
    payment: Payment;
    qrs: QRItem[];
  };

  export type NewCurrencyState =
    | 'PRE_REGISTRATION'
    | 'PRE_CONFIRM'
    | 'REGISTRATION'
    | 'CONFIRM';
  export type NewCurrency = {
    loading: { [key: string]: boolean };
    pageState: NewCurrencyState;
    oneTimeToken: string;
    regions: Region[];
    categories: Category[];
  };

  export type EditCurrency = {
    loading: boolean;
    isFailed: boolean;
  };

  export type TradeNework = {
    loading: boolean;
    tradeNetwork: NetWork;
  };

  export type Attribute = {
    loading: { [key: string]: boolean };
    categories: Category[];
    regions: Region[];
  };
  export type ErrorState = {
    errors: Array<Error>;
  };

  export type ChangePasswordState = 'INPUT_EMAIL' | 'INPUT_INFO' | 'SUCCESS';
  export type ChangePassword = {
    state: ChangePasswordState;
    email: string;
    oldPassword: string;
    newPassword: string;
    oneTimeToken: string;
    pinCode: string;
    loading: { [key: string]: boolean };
  };

  export type CurrencyList = {
    currencies: { [uuid: string]: Currency };
  };
}
