import {
  StoreType,
  Mutation,
  typesEnumeration,
  User,
  TransactionRate,
} from '../../types';

type MutationType =
  | 'setUserLoading'
  | 'setUsers'
  | 'setUserTransactionRate'
  | 'setLimit'
  | 'setOffset'
  | 'setPageIdx'
  | 'setUserId'
  | 'setUserName'
  | 'setPhoneNumber'
  | 'setUserTable'
  | 'setUserSize';

const mutations: Mutation<MutationType, StoreType.UserState> = {
  setUserLoading(state, loading: boolean) {
    state.loading = { ...state.loading, user: loading };
  },
  setUsers(state, users: User[]) {
    state.users = users.reduce(
      (acc, x) => ({ ...acc, [x.id]: x }),
      {} as {
        [id: string]: User;
      }
    );
  },
  setUserTransactionRate(
    state,
    { id, rate }: { id: string; rate: TransactionRate }
  ) {
    state.userTransactionRate = { ...state.userTransactionRate, [id]: rate };
  },
  setOffset(state, offset: number) {
    state.offset = offset;
  },
  setLimit(state, limit: number) {
    state.limit = limit;
  },
  setPageIdx(state, id: number) {
    state.pageIdx = id;
  },
  setUserId(state, userId: string) {
    state.userId = userId;
  },
  setUserName(state, userName: string) {
    state.userName = userName;
  },
  setPhoneNumber(state, phoneNumber: string) {
    state.phoneNumber = phoneNumber;
  },
  setUserTable(state, users: User[]) {
    const userObj = users.reduce(
      (acc, u) => ({ ...acc, [u.id]: u }),
      {} as { [id: string]: User }
    );
    state.usersTable = {
      ...state.usersTable,
      [state.offset / state.limit]: userObj,
    };
  },
  setUserSize(state, size: number) {
    state.userSize = size;
  },
};

export const MutationTypes = typesEnumeration(mutations);
export default mutations;
