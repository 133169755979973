import actions from './actions';
import mutations from './mutation';
import { Module } from 'vuex';
import { StoreType } from '../../types';

// state
const state: StoreType.Console = {
  loading: {
    logs: false,
    info: false,
  },
  totalAmount: 0,
  totalIssueAmount: 0,
  userNum: 0,
  trader: [],
  transaction: [],
  averageDegree: [],
  supplyAmount: [],
  averageClusterCoefficient: [],
  issueAmount: [],
  exponentRatio: [],
  averageTravelDistance: [],
};

// getters
const getters = {};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
} as Module<StoreType.Console, StoreType.RootState>;
