import request, { withToken, queryBuilder, errorHandle } from './request';
import {
  object,
  string,
  number,
  array,
  Decoder,
} from '@mojotech/json-type-validation';
import { BadRequest, Unauthorized, NotFound } from '../error';

export namespace Log {
  const logDecoder = object({
    date: string().map((x) => new Date(x)),
    value: number(),
  });

  const logRequest = (path: string) => <T>(decoder: Decoder<T>) => (
    token: string,
    offset: number,
    year: number,
    month?: number,
    day?: number
  ) =>
    request(
      `/analytics/log${path}?${queryBuilder({ offset, year, month, day })}`,
      withToken(token)
    )
      .then(errorHandle(400, new BadRequest()))
      .then(errorHandle(401, new Unauthorized()))
      .then((x) => x.json())
      .then(decoder.runPromise);

  // 通貨内ユーザー数
  export const totalPeople = (token: string) =>
    request('/analytics/total-people', withToken(token))
      .then(errorHandle(400, new BadRequest()))
      .then(errorHandle(401, new Unauthorized()))
      .then(errorHandle(404, new NotFound('通貨内ユーザー数')))
      .then((x) => x.json())
      .then(object({ value: number() }).runPromise);

  // 取引ログ
  export const getTradeLog = logRequest('/trader')(array(logDecoder));

  // トランザクションログ
  export const getTransactionLog = logRequest('/transaction')(
    array(logDecoder)
  );

  // 平均次数ログ
  export const getAverageDegreeLog = logRequest('/average-degree')(
    array(logDecoder)
  );

  // 供給量ログ
  export const getSupplyAmountLog = logRequest('/supply-amount')(
    array(logDecoder)
  );

  // 平均クラスタ係数ログ
  export const getAverageClusterCoefficientLog = logRequest(
    '/average-cluster-coefficient'
  )(array(logDecoder));

  // 通貨発行量ログ
  export const getIssueAmountLog = logRequest('/issue-amount')(
    array(logDecoder)
  );

  // 冪指数比 exponent-ratio
  export const getExponentRatioLog = logRequest('/exponent-ratio')(
    array(logDecoder)
  );

  // 全通貨平均移動距離 average-travel-distance
  export const getAverageTravelDistanceLog = logRequest(
    '/average-travel-distance'
  )(array(logDecoder));
}
