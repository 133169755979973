import * as Vuex from 'vuex';
import { StoreType, Action, typesEnumeration } from '@/store/types';
import { MutationType } from '@/store/modules/newCurrency/mutations';
import { ActionTypes as ErrorActionTypes } from '../error/actions';
import API from '@/api';
import * as Sentry from '@sentry/browser';

type ActionType =
  | 'preSignUp'
  | 'signUp'
  | 'nextState'
  | 'prevState'
  | 'setPrePostLoading'
  | 'setPostloading'
  | 'getCategories'
  | 'getRegions';

const moveState = (
  dir: 'prev' | 'next',
  state: StoreType.NewCurrencyState
): StoreType.NewCurrencyState => {
  const states: {
    [keys in StoreType.NewCurrencyState]: {
      prev: StoreType.NewCurrencyState;
      next: StoreType.NewCurrencyState;
    };
  } = {
    PRE_REGISTRATION: {
      prev: 'PRE_REGISTRATION',
      next: 'PRE_CONFIRM',
    },
    PRE_CONFIRM: {
      prev: 'PRE_REGISTRATION',
      next: 'REGISTRATION',
    },
    REGISTRATION: {
      prev: 'PRE_CONFIRM',
      next: 'CONFIRM',
    },
    CONFIRM: {
      prev: 'REGISTRATION',
      next: 'PRE_REGISTRATION',
    },
  };

  return states[state][dir];
};

const actions: Action<
  ActionType,
  StoreType.NewCurrency,
  StoreType.RootState
> = {
  async preSignUp({ rootState, commit, dispatch }, preForm) {
    try {
      dispatch(ActionTypes.setPrePostLoading, true);
      const { value } = await API.Admin.preSignUp(rootState.token, preForm);
      commit(MutationType.setOneTimeToken, value);
      dispatch(ActionTypes.nextState);
    } catch (e) {
      dispatch(`error/${ErrorActionTypes.pushError}`, e, { root: true });
      Sentry.captureException(new Error('preSignUp failure'));
    } finally {
      dispatch(ActionTypes.setPrePostLoading, false);
    }
  },
  async signUp({ state, rootState, dispatch }, mainForm) {
    try {
      dispatch(ActionTypes.setPostloading, true);
      await API.Admin.signUp(rootState.token, {
        ...mainForm,
        oneTimeToken: state.oneTimeToken,
      });
      dispatch(ActionTypes.nextState);
    } catch (e) {
      dispatch(`error/${ErrorActionTypes.pushError}`, e, { root: true });
      Sentry.captureException(new Error('signUp failure'));
    } finally {
      dispatch(ActionTypes.setPostloading, false);
    }
  },
  async getCategories({ rootState, dispatch, commit }) {
    try {
      const categories = await API.Admin.getCategories(rootState.token);
      commit(MutationType.setCategories, categories);
    } catch (e) {
      dispatch(`error/${ErrorActionTypes.pushError}`, e, { root: true });
    }
  },
  async getRegions({ rootState, commit, dispatch }) {
    try {
      const regions = await API.Admin.getRegions(rootState.token);
      commit(MutationType.setRegions, regions);
    } catch (e) {
      dispatch(`error/${ErrorActionTypes.pushError}`, e, { root: true });
    }
  },
  nextState({ state, commit }) {
    commit(MutationType.setState, moveState('next', state.pageState));
  },
  prevState({ state, commit }) {
    commit(MutationType.setState, moveState('prev', state.pageState));
  },
  setPostloading({ commit }, bool: boolean) {
    commit(MutationType.setLoading, { name: 'post', bool: bool });
  },
  setPrePostLoading({ commit }, bool: boolean) {
    commit(MutationType.setLoading, { name: 'prePost', bool: bool });
  },
};

export const ActionTypes = typesEnumeration(actions);
export default actions as Vuex.ActionTree<
  StoreType.NewCurrency,
  StoreType.RootState
>;
