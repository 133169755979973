import request, { withToken, queryBuilder, errorHandle } from './request';
import {
  object,
  string,
  array,
  number,
  Decoder,
} from '@mojotech/json-type-validation';
import {
  BadRequest,
  Unauthorized,
  NotFound,
  ServiceUnivailable,
  BadGateway,
} from '../error';

export namespace Admin {
  const logDecoder = object({
    date: string().map((x) => new Date(x)),
    value: number(),
  });

  const logRequest = (path: string) => <T>(decoder: Decoder<T>) => (
    id: string
  ) => (
    token: string,
    offset: number,
    year: number,
    month?: number,
    day?: number
  ) =>
    request(
      `/admin/reports/${id}${path}?${queryBuilder({
        offset,
        year,
        month,
        day,
      })}`,
      withToken(token)
    )
      .then(errorHandle(400, new BadRequest()))
      .then(errorHandle(401, new Unauthorized()))
      .then(errorHandle(404, new NotFound(path)))
      .then(errorHandle(502, new BadGateway()))
      .then(errorHandle(503, new ServiceUnivailable()))
      .then((x) => x.json())
      .then(decoder.runPromise);

  // 特定通貨取引人数レポート
  export const getTradeLogWithId = logRequest('/trader')(array(logDecoder));

  // トランザクションログ
  export const getTransactionLogWithId = logRequest('/transaction')(
    array(logDecoder)
  );

  // 平均次数ログ
  export const getAverageDegreeLogWithId = logRequest('/average-degree')(
    array(logDecoder)
  );

  // 供給量ログ
  export const getSupplyAmountLogWithId = logRequest('/supply-amount')(
    array(logDecoder)
  );

  // 平均クラスタ係数ログ
  export const getAverageClusterCoefficientLogWithId = logRequest(
    '/average-cluster-coefficient'
  )(array(logDecoder));

  // 通貨発行量ログ
  export const getIssueAmountLogWithId = logRequest('/issue-amount')(
    array(logDecoder)
  );

  // 冪指数比
  export const getExponentRatioLogWithId = logRequest('/exponent-ratio')(
    array(logDecoder)
  );
  // 全通貨平均移動距離
  export const getAverageTravelDistanceLogWithId = logRequest(
    '/average-travel-distance'
  )(array(logDecoder));
}
