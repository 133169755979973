import { StoreType, Action, typesEnumeration } from '../../types';
import { MutationTypes } from './mutations';
import { ActionTypes as ErrorActionTypes } from '../error/actions';
import API from '@/api';
import * as Sentry from '@sentry/browser';

type ActionType = 'initState' | 'getTradeNetwork';

const actions: Action<
  ActionType,
  StoreType.TradeNework,
  StoreType.RootState
> = {
  initState({ dispatch, getters, rootState, rootGetters }) {
    dispatch(ActionTypes.getTradeNetwork, rootGetters.calcOffset);
  },
  async getTradeNetwork(
    { rootState, commit, dispatch },
    { offset, year, month, day }
  ) {
    try {
      commit(MutationTypes.setLoading, true);
      const req = rootState.isAdmin
        ? API.Admin.getTradeNetworkWithId(rootState.currentCurrencyId)
        : API.Account.getTradeNetwork;

      const { nodes, links } = await req(
        rootState.token,
        offset,
        year,
        month,
        day
      );

      commit(MutationTypes.setTradeNetwork, {
        nodes: nodes.map((x, i) => ({ id: i, name: x })),
        links: links.flatMap((xs, i) => xs.map(x => ({ sid: i, tid: x }))),
      });
    } catch (e) {
      Sentry.captureException(new Error('getTradeNetwork failure'));
      dispatch(`error/${ErrorActionTypes.pushError}`, e, { root: true });
    } finally {
      commit(MutationTypes.setLoading, false);
    }
  },
};

export const ActionTypes = typesEnumeration(actions);
export default actions;
