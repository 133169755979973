import { Err } from '@mojotech/json-type-validation/dist/types/result';

export interface Error {
  message(): string;
}

export class DefaultError implements Error {
  constructor(private msg?: string) {}
  message() {
    return this.msg ? this.msg : 'Error';
  }
}

export class NotFound implements Error {
  constructor(private target: string) {}
  message() {
    return `${this.target}を取得できませんでした`;
  }
}

export class Unauthorized implements Error {
  message() {
    return '権限がなくなったため、ログアウトされました';
  }
}

export class BadRequest implements Error {
  constructor(private msg?: string) {}

  message() {
    return this.msg ? this.msg : '不正なリクエストです';
  }
}

export class Forbidden implements Error {
  constructor(private msg?: string) {}

  message() {
    return this.msg ? this.msg : 'アクセス権限がありません';
  }
}

export class InternalServerError implements Error {
  message() {
    return 'サーバーエラー';
  }
}

export class BadGateway implements Error {
  message() {
    return '502 Bad Gateway';
  }
}

export class ServiceUnivailable implements Error {
  constructor(private msg?: string) {}
  message() {
    return this.msg ? this.msg : 'サーバーはメンテナンス中です';
  }
}

export class loginError implements Error {
  message() {
    return '別端末でログインされました';
  }
}

export class wrongIdPass implements Error {
  message() {
    return 'メールアドレスが存在しないか、パスワードが間違っています';
  }
}
