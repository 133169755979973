import { StoreType, Mutation, typesEnumeration } from '../../types';

type MutationType =
  | 'setEmail'
  | 'setOldPassword'
  | 'setNewPassword'
  | 'setOneTimeToken'
  | 'setPinCode'
  | 'setState'
  | 'setLoading';

// mutations
const mutations: Mutation<MutationType, StoreType.ChangePassword> = {
  setEmail(store, { email }) {
    store.email = email;
  },
  setOldPassword(store, { oldPassword }) {
    store.oldPassword = oldPassword;
  },
  setNewPassword(store, { newPassword }) {
    store.newPassword = newPassword;
  },
  setOneTimeToken(store, { oneTimeToken }) {
    store.oneTimeToken = oneTimeToken;
  },
  setPinCode(store, { pinCode }) {
    store.pinCode = pinCode;
  },
  setState(store, { state }) {
    store.state = state;
  },
  setLoading(store, { state, bool }: { state: string; bool: boolean }) {
    store.loading = { ...store.loading, [state]: bool };
  },
};

export const MutationTypes = typesEnumeration(mutations);
export default mutations;
