import { Module, GetterTree } from 'vuex';
import { StoreType } from '../../types';
import actions from './actions';
import mutations from './mutations';

const state: StoreType.Attribute = {
  loading: {
    getCategory: false,
    getRegion: false,
    addCategory: false,
    editCategory: false,
    deleteCategory: false,
    patchRegion: false,
  },
  categories: [],
  regions: [],
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
} as Module<StoreType.Attribute, StoreType.RootState>;
